import self from '../..';

const GeometryUtility = self.app.modules.geometryUtility;
const OptionController = self.app.modules.optionManager.optionController;

export default class DisplayGroupHelper {
    static setProductVisibility(product, visibility, fromGroup = false) {
        if (!product.mesh) {
            return;
        }
        product.visible = visibility;

        GeometryUtility.toggleMeshVisibility(product.mesh, visibility, false);

        if (product.mesh.selectMesh) {
            GeometryUtility.toggleMeshVisibility(product.mesh.selectMesh, visibility, false);
        }

        OptionController.toggleOptionsVisibility(visibility, {
            entity: product,
            optionFlag: OptionController.optionsFamilies.ALL,
        });
        if (!fromGroup) {
            self.app.modules.history.snapshot();
        }
    }

    static setGroupVisibility(group, visibility) {
        group.productList.forEach((product) => {
            DisplayGroupHelper.setProductVisibility(product, visibility, true);
        });
        self.app.modules.history.snapshot();
    }

    static isGroupVisible(group) {
        // Only for default group
        if (group.productList.length === 0) {
            return true;
        }
        return group.productList.some((product) => product.visible);
    }
}
