/* eslint max-classes-per-file: 0 */
import self from '.';
import EntitiesData from '../../entities-data';
import config from 'defaultConfig';

import { Mesh, SceneLoader, MeshBuilder, Vector3 } from '@babylonjs/core';
import { OBJFileLoader } from '@babylonjs/loaders/OBJ';
import '@babylonjs/loaders/glTF';

import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';

const MeshUtility = require('./utility/mesh-utility');

const { ENTITY_TYPE } = EntitiesData;

const CORNER_SIZE = 62;

class MeshInfos {
    constructor(mesh, snapInfos = null, clones = {}) {
        this.mesh = mesh;
        this.clones = clones;
        this.snapInfos = snapInfos;
    }
}

export default class MeshManager {
    /**
     * @class bematrix.MeshManager
     * */
    constructor() {
        /** @type String
         * Meshes extension */
        this.fileExtension = config.fileType;

        /** @type String
         * Meshes path */
        this.path = '/assets/models/';

        /** @type Number
         * Scale applied to the loaded meshes */
        this.scaling = 0.001;

        this.MaterialManager = self.app.modules.materialManager;

        this.loadedMeshes = {};

        // Skip material for obj meshes as we can't load them
        OBJFileLoader.SKIP_MATERIALS = true;
        self.app.events.on('@obsidian-engine.engine-ready', (scene) => {
            /** @type Scene  */
            this.scene = scene;
        });

        // Set to true if you want to use the UI and the script to rework the Straight frames .obj
        this.reworkObjScript = false;
        if (this.reworkObjScript) {
            const splitUi = require('./utility/split-ui');
            splitUi.meshManager = this;
            splitUi.split();
        }
    }

    /**
     *  Return a promise with a new instance of the corresponding mesh and material
     *  Load the geometry if needed
     * @param {String} ref
     * @param {String} materialId
     * @returns {Promise}
     */
    getMeshAsync(ref, materialId) {
        return new Promise((resolve) => {
            const meshInfos = this.loadedMeshes[ref];
            if (meshInfos) {
                resolve(this.getMeshFromLoadedGeometry(ref, materialId, meshInfos));
            } else {
                this.loadGeometry(ref).then((infos) => {
                    // infos == meshInfos but it's a parameter of a callback function
                    resolve(this.getMeshFromLoadedGeometry(ref, materialId, infos));
                });
            }
        });
    }

    /**
     * Return a new instance of the corresponding mesh and material
     * Its geometry must already be loaded
     * @param {String} ref
     * @param {String} materialId
     * @param {Object} meshInfos
     * @returns {InstancedMesh}
     */
    getMeshFromLoadedGeometry(ref, materialId, meshInfos = null, entityInfo = null) {
        let infos = meshInfos;
        if (!infos) {
            infos = this.loadedMeshes[ref];
        }

        if (!infos) {
            self.app.log.error(`MeshManager : trying to instanciate a mesh from an undefined geometry ! (ref : ${ref} )`);
            return null;
        }

        if (!infos.clones[materialId]) {
            this.MaterialManager.setFramesTransparency(false); // Disable transparency

            const clone = infos.mesh.clone(`${ref}-${materialId}`);

            // Material of the main mesh
            clone.material = this.MaterialManager.loadMaterial(materialId);

            const ralColor = materialId.startsWith('RAL') ? materialId.split('-').pop() : null;
            // Custom material for child meshes
            clone.getChildMeshes().forEach((m) => {
                const hasCustomMaterial = m.name.toLowerCase().includes('custom');
                if (!hasCustomMaterial) {
                    if (m.material?.lightmapTexture) {
                        const fileName = m.material.lightmapTexture.name;
                        const newName = ralColor ? `RAL-${fileName.split('.')[0]}-${ralColor}` : m.material.name;
                        m.material = this.MaterialManager.addLightmapTextureToMaterial(fileName, newName, clone.material);
                    } else if (ralColor) {
                        m.material = this.MaterialManager.loadMaterial(materialId);
                    } else if (entityInfo.category === 'INFILLS') {
                        if (m.name.includes('isFront')) {
                            m.material = this.MaterialManager.loadMaterial(materialId);
                        }
                    }
                }
                m.makeGeometryUnique();
            });

            // The clone's geometry must be unique to be able to create an instance of it
            clone.makeGeometryUnique();

            clone.setEnabled(false);
            infos.clones[materialId] = clone;
        }

        const instance = infos.clones[materialId].createInstance(`${ref}-${uuid()}`);
        // Add subcolliders to the clone
        if (infos.mesh.subCollidersBB) {
            instance.subCollidersBB = infos.mesh.subCollidersBB;
        }
        infos.clones[materialId].getChildMeshes().forEach((sm) => {
            const c = sm.createInstance(`${ref}sub-${uuid()}`);
            c.parent = instance;
        });

        instance.position = Vector3.Zero();
        instance.computeWorldMatrix(true);

        MeshUtility.AddMetadataProperties(instance, { ref });

        return instance;
    }

    /**
     * Can only generate squared frames
     * @param {string} ref
     * @param {*} entityType needed to parse dimensions correctly
     */
    generateMeshFromRef(ref, entityType = ENTITY_TYPE.STRAIGHT) {
        const { width, height, depth } = this.getDimensionsFromRef(ref, entityType);
        if (depth === 0) {
            const mesh = MeshBuilder.CreatePlane(ref, { width, height, sideOrientation: Mesh.DOUBLESIDE });
            return mesh;
        }
        return MeshBuilder.CreateBox(ref, { width, height, depth }, this.scene);
    }

    /**
     * Generate a mesh out the reference using generateFromRef
     * This mesh will bind the the reference in the m eshes catalog
     * @param {string} ref
     * @param {*} options
     */
    loadProceduralGeometry(ref, options = {}) {
        const entityType = EntitiesData.entityTypeFromSubCategory(options.subCategory);
        const proceduralMesh = this.generateMeshFromRef(ref, entityType);
        const meshInfos = this._handleImportedData({ meshes: [proceduralMesh] }, options);
        const { mesh } = meshInfos;
        mesh.setEnabled(false);
        this.loadedMeshes[ref] = meshInfos;
        return meshInfos;
    }

    /**
     * Load the geometry corresponding to the reference
     * @param {String} ref
     * @param {String} url
     * @returns {Promise}
     */
    loadGeometry(ref, url, options = {}) {
        // Otherwise we load the geometry before instanciating the mesh with its material
        return SceneLoader.ImportMeshAsync('', url, '', this.scene, null, this.fileExtension)
            .then((importedData) => {
                options.ref = ref;
                options.objName = ref;
                const meshInfos = this.getMeshInfos(importedData, options);
                const { mesh } = meshInfos;
                mesh.setEnabled(false);
                this.loadedMeshes[ref] = meshInfos;
                return meshInfos;
            })
            .catch((err) => {
                self.app.log.error(err);
            });
    }

    /**
     * @param {CatalogItem[]} products
     */
    loadGeometries(products) {
        const promises = [];
        Object.keys(products).forEach((ref) => {
            const prod = products[ref];
            if (prod.isAvailable) {
                promises.push(
                    this.loadGeometry(ref, prod.url, {
                        subCategory: prod.subCategory,
                        category: prod.category,
                        ref,
                    }),
                );
            }
        });
        return Promise.all(promises);
    }

    addDebugMesh() {
        SceneLoader.Append('assets/data/', 'xyz.glb', this.scene);
    }

    getMeshInfos(data, options) {
        let mainMesh;
        const otherMeshes = [];
        const subCollidersBB = [];
        let hasNativeRoot = false;
        this.MaterialManager.setFramesTransparency(false); // Disable transparency
        data.meshes.forEach((mesh) => {
            if (mesh.name.includes('ROOT')) {
                hasNativeRoot = true;
                mainMesh = mesh;
                mainMesh.parent = null;
            } else if (mesh.name.toLowerCase().includes('collider')) {
                mesh.parent = null;
                subCollidersBB.push(MeshUtility.GenerateSubBoundingBox(mesh));
                mesh.dispose();
            } else if (mesh.name !== '__root__') {
                otherMeshes.push(mesh);
            }
        });

        if (!mainMesh) {
            mainMesh = MeshBuilder.CreatePlane('plane', { width: 0.001, height: 0.001 });
            mainMesh.isVisible = false;
        } else {
            mainMesh.material.dispose(true, true);
            mainMesh.material = this.MaterialManager.loadMaterial('frames-ano');
        }

        mainMesh.subCollidersBB = subCollidersBB;

        otherMeshes.forEach((mesh) => {
            if (!hasNativeRoot) {
                mesh.parent = mainMesh;
            }
            // Convert the material maps and remove the original ones (with useless loaded textures)
            const oldMat = mesh.material;
            if (mesh.name.toLowerCase().includes('holes')) {
                mesh.material = this.MaterialManager.addLightmapTextureToMaterial('holes.webp', 'ECO-holes');
                oldMat.dispose(true, true);
            } else if (mesh.name.toLowerCase().includes('slot')) {
                mesh.material = this.MaterialManager.addLightmapTextureToMaterial('slot.webp', 'ECO-slot');
                oldMat.dispose(true, true);
            } else if (mesh.name.toLowerCase().includes('cap')) {
                mesh.material = this.MaterialManager.getCapMaterial(mesh.material);
                oldMat.dispose(true, true);
            } else if (mesh.name.toLowerCase().includes('led')) {
                mesh.material = this.MaterialManager.getLedskinMaterial(mesh.material);
                oldMat.dispose(true, true);
            } else if (options.category === 'INFILLS') {
                if (mesh.name.includes('isFront')) {
                    mesh.material = this.MaterialManager.loadMaterial('base-infill-material');
                } else {
                    mesh.material = this.MaterialManager.loadMaterial('back-infill-material');
                }
            }
        });

        return new MeshInfos(mainMesh, null);
    }

    /**
     * Check if the imported mesh needs more treatement
     * (snap faces, split, merge, scale, uvMapping...)
     * @param {Object} data
     * @param {Object} options
     */
    // TODO: remove this when glb will be used
    _handleImportedData(data, options) {
        /** @type Mesh */
        let mainMesh;
        let meshes;
        let splitted = false;
        let snapFacesInfos = null;

        const underscoreRootIndex = data.meshes.findIndex((mesh) => mesh.name.includes('__root__'));
        if (underscoreRootIndex > -1) {
            data.meshes[underscoreRootIndex].getChildMeshes(true).forEach((child) => {
                child.parent = null;
            });
            data.meshes.splice(underscoreRootIndex, 1);
        }

        // models already uv mapped only have 2 meshes in total
        // Those needing UVs have more
        let doUvMap;
        if (options.isConnector) {
            doUvMap = false;
        } else {
            doUvMap =
                (options.category === 'FRAMES' ||
                    options.category === 'DOOR FRAMES' ||
                    options.subCategory === 'BETRUSS b310' ||
                    options.subCategory === 'BETRUSS SQUARE' ||
                    (options.subCategory === 'BETRUSS PARTS' && options.ref.startsWith('852')) ||
                    (options.subCategory === 'POP-IN' && options.ref.startsWith('446')) ||
                    this.reworkObjScript) &&
                data.meshes.length !== 2 &&
                options.subCategory !== 'GLASS FRAMES';
        }
        if (this.reworkObjScript && data.meshes.length === 1) {
            // need split => split script
            meshes = MeshUtility.SplitFacesWithHoles(data.meshes[0]);
            splitted = true;
        } else {
            meshes = data.meshes || [];
        }
        // Look for the root mesh

        for (let i = 0; i < meshes.length; i += 1) {
            const mesh = meshes[i];

            if (!mesh.subMeshes) {
                mesh.subMeshes = [];
            }
            if (mesh.name.includes('Root')) {
                mainMesh = mesh;
                meshes.splice(i, 1);
                break;
            }
        }

        if (!mainMesh) {
            mainMesh = meshes[0];
            mainMesh.parent = null;
            meshes.splice(0, 1);
        }

        // Todo: needScale don't resolve all cases
        /* const needScale = MeshManager.NeedScale(mainMesh);
        console.log("needScale", needScale, data, mainMesh.scaling.toString()); */
        // Apply scale to geometry if needed
        if (options.isConnector) {
            mainMesh.scaling.scaleInPlace(this.scaling * -1);
            mainMesh.bakeCurrentTransformIntoVertices();

            meshes.forEach((m) => {
                m.scaling.scaleInPlace(this.scaling * -1);
                m.bakeCurrentTransformIntoVertices();
            });
        }

        meshes.forEach((m) => {
            m.parent = mainMesh;
        });
        const needMerge = options.subCategory === 'CORNER PROFILES' || options.subCategory === 'TRACK LIGHT START';
        const needSnap = meshes.length > 0 && !options.isConnector;

        const subCollidersBB = MeshUtility.GenerateSubBoundingBoxes(data.meshes);
        MeshUtility.RemoveColliders(mainMesh, meshes);

        // => UV applied and mesh merging only if needed
        if (doUvMap) {
            const prefix = options.ref.split(' ')[0];
            const isL = prefix === '690' || prefix === '670';
            const holeInfos = MeshUtility.MapHoles(
                meshes,
                options.subCategory === 'CURVED FRAMES' && !splitted,
                options.subCategory === 'PERFECT CORNERS' && !splitted,
                isL,
            );
            // (curved frames must not have holes on top and bot pars,
            // also a real curved can't have been splitted)

            // eslint-disable-next-line prefer-destructuring
            mainMesh = holeInfos.mainMesh;

            // We need snap face infos for curved frame and perfect corners
            // (no simple computation possible with the bounding box)
            // eslint-disable-next-line prefer-destructuring
            snapFacesInfos = holeInfos.snapFacesInfos;
        } else if (needSnap) {
            // if no Uv map => check if there is some snap meshes to get the snap infos
            const snapMeshes = meshes.filter((m) => m.id.includes('snap'));
            snapFacesInfos = MeshUtility.ComputeAllSnapsInfo(snapMeshes);
            if (snapFacesInfos.length === 0) {
                snapFacesInfos = null;
            } else {
                snapMeshes.forEach((sm) => {
                    sm.dispose();
                });
            }
        }

        mainMesh.subCollidersBB = subCollidersBB;

        if (this.reworkObjScript && options.exportObj) {
            const child = mainMesh.getChildren()[0];
            const obj = child ? MeshUtility.ToOBJ([mainMesh, child]) : MeshUtility.ToOBJ([mainMesh]);
            const blob = new Blob([obj], { type: 'text/plain' });
            saveAs(blob, options.objName);
        }

        if (needMerge) {
            mainMesh = Mesh.MergeMeshes([mainMesh, ...mainMesh.getChildren()], true, true);
        }

        return new MeshInfos(mainMesh, snapFacesInfos);
    }

    /**
     * Extract dimensions of the object out of it's reference
     * /!\ CAREFULL ! This is mainly used for Bematrix rules like infill positionning or swapping an
     * object. This must not be used in a geometrical case, when you should instead use the mesh
     * bounging box. /!\
     * @param {String} ref reference of the object
     * @param {*} entityType needed to know the reference format
     * @returns {*} object containing with, height and depth properties
     */
    getDimensionsFromRef(ref, entityType = ENTITY_TYPE.STRAIGHT) {
        const refArray = ref.split(' ');
        let depth = parseInt(refArray[3], 10) * this.scaling;
        let width = parseInt(refArray[1], 10) * this.scaling;
        let height = parseInt(refArray[2], 10) * this.scaling;

        if (entityType === ENTITY_TYPE.COVER) {
            if (refArray[refArray.length - 1] === 'D00') {
                depth = width;
                height = 0.0085;
                width = 0.062;
            } else {
                depth = height;
            }
        } else if (entityType === ENTITY_TYPE.CORNER || entityType === ENTITY_TYPE.STRUCTURAL) {
            depth = width;
            width = CORNER_SIZE * this.scaling;
            height = CORNER_SIZE * this.scaling;
        } else if (entityType === ENTITY_TYPE.PERFECT) {
            depth = width;
            if (refArray[0] === '691' || refArray[0] === '671') {
                width = 0.496; // Only width for 691
            }
        } else if (entityType === ENTITY_TYPE.TRACKSTART) {
            depth = height;
            width = CORNER_SIZE * this.scaling;
            height = CORNER_SIZE * this.scaling;
        } else if (ENTITY_TYPE.SIDELED) {
            if (refArray[0] === '786') {
                depth = 0.184;
            } else if (refArray[0] === '781') {
                depth = 0.124;
            } else {
                depth = 0.058;
            }
        } else if (
            entityType === ENTITY_TYPE.STRAIGHT ||
            entityType === ENTITY_TYPE.BACKLED ||
            entityType === ENTITY_TYPE.MOTIONSKIN ||
            entityType === ENTITY_TYPE.GLASS
        ) {
            depth = 0.062;
        }

        if (Number.isNaN(width) || Number.isNaN(height) || Number.isNaN(depth)) {
            self.app.log.error('NaN dimension');
        }

        return { width, height, depth };
    }

    /**
     * Return true if the mesh need to be scaled
     * @param {Mesh} mesh
     */
    static NeedScale(mesh) {
        const bb = MeshUtility.GetBoundingBox(mesh);
        if (bb.maximum.x - bb.minimum.x > 5 || bb.maximum.y - bb.minimum.y > 5 || bb.maximum.z - bb.minimum.z > 5) {
            return true;
        }
        return false;
    }
}
