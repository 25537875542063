import EntitiesData from '../../../../entities-data';

import DuplicateControl from '../models/duplicate-control';
import SelectionHelper from '../helpers/selection-helper';

import self from '../..';
import { mouseToWorld } from '../../../../helpers/scene-helper';
import ConsoleHelper from '../../../../helpers/console-helper';
import eventBus from '../../../../commons/EventBus';
import RightsHelper from 'helpers/rights-helper';
import config from 'defaultConfig';
import VueHelper from 'helpers/vue-helper';
import { TransformNode, Quaternion, Vector3, SpriteManager, Space } from '@babylonjs/core';

const {
    events,
    modules: {
        optionManager: { optionController: OptionController },
        obsidianEngine: { controller: engineController },
        geometryUtility,
        gizmoManager: { controller: gizmoController },
        history,
        dataStore,
        entityManager: { Controller: EntityController, Helper: EntityHelper },
        cameraManager: { controller: CameraController, ScreenshotController },
        guiManager,
        meshManager: { meshUtility },
        measurementManager: { Controller: measurementController },
        highlightManager,
    },
} = self.app;

export default class SelectionController {
    constructor() {
        this.engine = null;
        this.scene = null;
        this.nodeMesh = null;
        this.copying = false;
        this.selectedMeshes = [];
        this.spriteManagerDuplicate = null;
        this._dragStartPosition = new Vector3();
        this._dragStartCenter = new Vector3();
        this._currentOffset = new Vector3();
        this._highlightBehavior = null;
        this._isDragging = false;

        if (engineController.ready) {
            this.scene = engineController.scene;
            this.engine = engineController.engine;
            this.initSelectionController();
        } else {
            events.on('@obsidian-engine.engine-ready', (scene) => {
                this.scene = scene;
                this.engine = engineController.engine;
                this.initSelectionController();
            });
        }

        eventBus.on('activating-tool', (toolName) => {
            if (toolName !== 'gizmo') {
                this.removeControlToCurrentEntity();
                this.deactivateGizmo();
            }
        });

        // vue binding
        const that = this;
        this.vueData = {
            // yep you can put here some getter-setters to handle vue datas better
            get multiSelectedMesh() {
                return that.selectedMeshes;
            },

            /**
             * As this value is an integer vue don't keep a reference of it but a value of it
             * that's why i created the object below
             */
            currentEntityBaseplateType: {
                get data() {
                    if (that.selectedMeshes.length === 1) {
                        return that.selectedMeshes[0].entity.getBaseplateOption();
                    }
                    return null;
                },
            },
            currentEntityGroundplateType: {
                get data() {
                    if (that.selectedMeshes.length === 1) {
                        return that.selectedMeshes[0].entity.getGroundplateOption();
                    }
                    return null;
                },
            },
            currentEntityLightType: {
                get data() {
                    if (that.selectedMeshes.length === 1) {
                        return that.selectedMeshes[0].entity.getLightOption();
                    }
                    return null;
                },
            },
        };

        VueHelper.AddVueProperty(this, 'duplicationActive', false);
        this.meshesToInitialize = null;

        this.initEvents();

        ConsoleHelper.ignoreWarningWith('lastSelectedEntity');
        ConsoleHelper.ignoreWarningWith('selectedMeshes');

        ConsoleHelper.expose('selectionController', this);
    }

    initSelectionController() {
        this.spriteManagerDuplicate = new SpriteManager('duplicateManager', 'assets/images/duplication.png', 6, 256, this.scene);
        this.spriteManagerDuplicate.isPickable = true;

        this.dragSizeViewer = new guiManager.DragSizeViewer(guiManager.GuiController, this.scene);
    }

    /**
     * Initialive all events related to the selection
     */
    initEvents() {
        events.on('@gizmo-manager.show-gizmo', () => {
            this.removeControlToCurrentEntity();
        });

        events.on('@entity-manager.recreated-mesh', (entity) => {
            // Update selected meshes: dispose previous selectMesh if there is, and create new one based on old
            const index = this.selectedMeshes.findIndex((m) => m.entity.id === entity.id);
            if (index >= 0) {
                this.selectedMeshes[index].dispose();
                this.selectedMeshes.splice(index, 1);
                this.selectedMeshes.push(SelectionHelper.createSelectMesh(entity));
            }
        });

        events.on('duplicated', (mesh) => {
            this.unselectMesh(mesh);
            this.selectMesh(mesh);
            this.addControlToCurrentEntity(mesh);
        });

        // -- Snapping Events

        events.on('@snapping.snap', (snappingParams) => {
            this.onSnap(snappingParams.snappingEntity.mesh);
        });

        events.on('@snapping.ghost', () => {
            this.toggleSelectMeshVisibility(false, false);
        });

        events.on('@snapping.unghost', () => {
            this.toggleSelectMeshVisibility(true, false);
        });

        // -- Engine events : keyboard, mouse, drag, selection..

        events.on('@obsidian-engine.undo', () => {
            this.unselectAll();
            history.back();
        });

        events.on('@obsidian-engine.redo', () => {
            this.unselectAll();
            history.forward();
        });

        events.on('@obsidian-engine.copy', () => {
            this.copyCurrentSelection();
        });

        events.on('@obsidian-engine.copyAndMove', () => {
            const copies = this.copyCurrentSelection(false);
            if (copies.length) {
                this.selectMeshes(copies);
                this.showSelectMeshPositionGizmo();
            }
        });

        events.on('@obsidian-engine.copyAndRotate', () => {
            const copies = this.copyCurrentSelection(false);
            if (copies.length) {
                this.selectMeshes(copies);
                this.showSelectMeshRotationGizmo();
            }
        });

        events.on('@obsidian-engine.copyAndReplace', () => {
            if (this.selectedMeshes.length !== 1) {
                return;
            }
            const copies = this.copyCurrentSelection(false);
            if (copies.length) {
                this.selectMeshes(copies);
                this.showSelectMeshScaleGizmo();
            }
        });

        events.on('@obsidian-engine.paste', () => {
            this.pasteCurrentCopy();
        });
        events.on('@obsidian-engine.move', () => {
            this.showSelectMeshPositionGizmo();
        });
        events.on('@obsidian-engine.rotate', () => {
            this.showSelectMeshRotationGizmo();
        });
        events.on('@obsidian-engine.rotate90', (axis) => {
            this.rotateCurrentSelection(Math.PI / 2, axis);
        });
        events.on('@obsidian-engine.rotate45', (axis) => {
            this.rotateCurrentSelection(Math.PI / 4, axis);
        });
        events.on('@obsidian-engine.scale', () => {
            if (
                this.selectedMeshes.length === 1 &&
                EntitiesData.entityTypeFromSubCategory(this.selectedMeshes[0].entity.subCategory, this.selectedMeshes[0].entity.ref)
            ) {
                this.showSelectMeshScaleGizmo();
            }
        });

        events.on('@obsidian-engine.duplicate', () => {
            this.toggleDuplication();
        });

        events.on('@obsidian-engine.dragging', () => this.dragSelected());

        events.on('@obsidian-engine.drag-start', (position) => {
            this.initDrag(position);
        });

        events.on('@obsidian-engine.drag-end', () => {
            this.stopDragMesh();
        });
        events.on('@obsidian-engine.select-mesh', (mesh, isMultiSelect) => {
            if (this.meshesToInitialize) {
                return; // We are initializing a mesh on the scene, we block the event
            }
            if (!isMultiSelect) {
                this.unselectAll();
            }
            this.selectMesh(mesh);
        });
        events.on('@obsidian-engine.unselect-mesh', (mesh) => {
            this.unselectMesh(mesh);
            if (!this.selectedMeshes.length) {
                events.emit('last-unselected'); // Prevent dragging when nothing selected
            }
        });

        events.on('@obsidian-engine.cancel', () => {
            this.cancel();
        });

        // -- Camera Events

        events.on('@camera-manager.start-screenshot-behavior', () => {
            this.toggleSelectionHighlight(false);
        });

        events.on('@camera-manager.end-screenshot-behavior', () => {
            this.toggleSelectionHighlight();
        });

        // -- Events provoking an unselection

        const unselectAllEvents = [
            '@project-manager.clear-scene',
            '@obsidian-engine.unselect',
            '@measurement-manager.measurement-selected',
            '@bematrix-ui.toggle-products',
        ];

        unselectAllEvents.forEach((event) => {
            events.on(event, () => {
                this.unselectAll();
            });
        });
    }

    selectMesh(mesh) {
        if (!this.selectedMeshes.length) {
            this.selectEntity(mesh);
        } else {
            this.multiSelectMesh(mesh.entity);
        }
        ConsoleHelper.expose('lastSelectedEntity', mesh.entity);
        events.emit('select-entity-scene', mesh.entity);
    }

    selectMeshes(meshes) {
        this.unselectAll();
        meshes.forEach((mesh) => {
            this.selectMesh(mesh.mesh);
        });
    }

    /**
     * Custom function for when we need to select meshes without checking the groups
     * The group selection triggers other selections, and we unsync in that case.
     * @param {Array<Mesh>} meshes meshes to select
     */
    selectMeshesNoGroup(meshes) {
        meshes.forEach((mesh) => {
            if (!this.selectedMeshes.length) {
                this.selectEntity(mesh.mesh);
            } else {
                this.multiSelectMesh(mesh.mesh.entity);
            }
            events.emit('select-entity-scene-no-group', mesh.mesh.entity);
        });
    }

    unselectMesh(mesh) {
        const { entity } = mesh;
        // It means that there is only one mesh selected
        if (this.selectedMeshes.length === 1) {
            this.unselectEntity();
        } else {
            this.deactivateGizmo();
            this.removeOneMultiSelectMesh(entity);
            if (this.selectedMeshes.length === 1) {
                events.emit('select-entity', this.selectedMeshes[0].entity);
            }
        }
        events.emit('unselect-entity-scene', entity);
    }

    /**
     * Cancel current operation (finish it and go back in history)
     * If there is no operation, unselect everything.
     */
    cancel() {
        const prevHistory = history.snapshots[0];
        this.stopDragMesh();
        this.unselectAll();
        if (prevHistory !== history.snapshots[0]) {
            // If history changed, go back in history and trim it.
            history.back();
            history.snapshots.splice(0, history.pointer);
            history.pointer = 0;
        }
    }

    /**
     * Remove the drag behavior from the selectMesh
     */
    stopDragMesh() {
        let isNeedUnselect = false;
        this.dragSizeViewer.togglePlaneModeVisibility(false);
        if (this.selectedMeshes.length) {
            for (let i = 0; i < this.selectedMeshes.length; i++) {
                this.selectedMeshes[i].entity.updatePosition();
                if (this.selectedMeshes[i].entity.updateRectsMatrixes) {
                    this.selectedMeshes[i].entity.updateRectsMatrixes();
                }
                SelectionHelper.checkRulesOfSelectMesh(this.selectedMeshes[i]);
            }
            gizmoController.hideGizmos();
            if (this.meshesToInitialize) {
                if (this.copying) {
                    this.copying = false;
                }
                if (this.meshesToInitialize.length === 1) {
                    isNeedUnselect = true;
                    events.emit('end-initialization');
                }
                this.meshesToInitialize = null;
            }
            if (this._isDragging) {
                history.snapshot();
            }
        }
        if (!RightsHelper.isModeBuildingPlan()) {
            CameraController.activateCamera(true); // Restore camera rotation
        }
        this._isDragging = false;
        if (isNeedUnselect) {
            this.unselectAll();
        }
    }

    /**
     * Unselect everything on scene
     */
    unselectAll() {
        this.destroyNodeMesh();

        while (this.selectedMeshes.length) {
            this.unselectMesh(this.selectedMeshes[0]);
        }

        events.emit('unselect-all');
    }

    initDrag(position) {
        if (RightsHelper.isModeBuildingPlan() || RightsHelper.isModePublic()) {
            return;
        }
        this.selectedMeshes.forEach((sMesh) => SelectionHelper.checkRulesOfSelectMesh(sMesh, false)); // Reset selection to green
        CameraController.activateCamera(false); // Prevent camera rotation during drag
        this._dragStartPosition = position;
        this._currentOffset = new Vector3();
        this._dragStartCenter = SelectionHelper.getBoundsOfSelection(this.selectedMeshes).centerWorld;
        this.dragSizeViewer.setOriginPointPosition(this._dragStartCenter, false);
        this.dragSizeViewer.setEndPointPosition(this._dragStartCenter);
        this._isDragging = true;
        // Deactivate all current gizmos
        this.deactivateGizmo();
        this.removeControlToCurrentEntity();
    }

    dragSelected() {
        if (!this._isDragging) {
            return;
        }
        const meshes = this.selectedMeshes;

        for (let i = 0; i < meshes.length; i++) {
            meshes[i].position.subtractInPlace(this._currentOffset);
        }
        this._currentOffset = mouseToWorld(this.engine, this.scene, new Vector3(0, 1, 0), this._dragStartPosition.y).subtract(
            this._dragStartPosition,
        );
        this._currentOffset.x -= this._currentOffset.x % config.step;
        this._currentOffset.z -= this._currentOffset.z % config.step;
        for (let i = 0; i < meshes.length; i++) {
            meshes[i].position.addInPlace(this._currentOffset);
            meshes[i].computeWorldMatrix(true);
            if (meshes[i].entity) {
                meshes[i].entity.updatePosition();
            }
        }

        if (!this.meshesToInitialize) {
            // Set gui points position
            if (this.selectedMeshes.length === 1) {
                // When only one mesh selected, show gizmos in local mesh axis
                // The axis is local forward without y component normalized.
                let axis = this.selectedMeshes[0].forward;
                if (Math.abs(axis.y) > 0.8) {
                    // If facing mostly up/down, use up axis instead
                    axis = this.selectedMeshes[0].up;
                }
                axis.y = 0;
                this.dragSizeViewer.setPointFromMeshInAxis(this.selectedMeshes[0], Vector3.Normalize(axis));
            } else {
                this.dragSizeViewer.setEndPointPosition(this._dragStartCenter.add(this._currentOffset));
            }
            this.dragSizeViewer.togglePlaneModeVisibility(true);
        }
    }

    /**
     * Called on selecting a mesh
     * The difference with selectEntityOnScene is that the other emit an event
     * that can be used for an UI module wich update itself in case of changing
     * when we select something
     * @param {*} mesh
     * @param {*} dragging
     * @param {Boolean} checkGizmo
     * @param {Boolean} emitEvent
     */
    selectEntity(mesh, checkGizmo = false, emitEvent = true) {
        const { currentGizmo } = gizmoController;
        this.deactivateGizmo();
        this.removeCurrentSelectMesh();
        mesh.entity.unfreeze();
        OptionController.lightController.updateCanHaveLights();
        this.selectedMeshes.push(SelectionHelper.createSelectMesh(mesh.entity));
        ConsoleHelper.expose('selectedMeshes', this.selectedMeshes);
        if (checkGizmo && currentGizmo) {
            gizmoController.showSpecificGizmo(currentGizmo, this.selectedMeshes[0]);
        }
        if (emitEvent) {
            events.emit('select-entity', this.selectedMeshes[0].entity);
        }
    }

    /**
     * Add this entity's mesh to the selectedMesh array
     * @param {*} entity
     */
    multiSelectMesh(entity) {
        this.removeControlToCurrentEntity();
        this.deactivateGizmo();
        entity.unfreeze();
        const selectMesh = SelectionHelper.createSelectMesh(entity);
        this.selectedMeshes.push(selectMesh);
    }

    /**
     * Called on unselecting a mesh
     */
    unselectEntity() {
        this.deactivateGizmo();
        this.stopDragMesh();

        this.removeControlToCurrentEntity();
        this.removeCurrentSelectMesh();
    }

    /**
     * Delete all the products that are selected
     */
    deleteAllSelected() {
        if (this.selectedMeshes.length === 1) {
            this.removeCurrentEntity();
            this.removeControlToCurrentEntity();
            history.snapshot();
            return;
        }
        while (this.selectedMeshes.length) {
            const selectMesh = this.selectedMeshes.pop();
            const entityToRemove = selectMesh.entity;
            entityToRemove.mesh.selectMesh = null;
            selectMesh.dispose();
            dataStore.removeEntity(entityToRemove.id);
        }
        this.deactivateGizmo();
        history.snapshot();
    }

    /**
     * Remove an entity by it's instance
     * Usefull when removing an entity from the UI
     * @param {*} entity
     */
    deleteEntityByRef(entity) {
        if (this.selectedMeshes.length !== 1 && entity.mesh.selectMesh) {
            this.unselectMesh(entity.mesh.selectMesh);
        }
        dataStore.removeEntity(entity.id);
        history.snapshot();
    }

    /**
     * Called when we add a Frame on the scene. Enables the drag and drop from
     * the catalog
     * @param {*} entity the entity we add on the scene
     * @param {*} pointerInfo the pointerInfo of the mouseEvent (x,y)
     */
    initializingEntityOnScene(entity, pointerInfo, dragging = true) {
        this.unselectAll();

        if (!pointerInfo) {
            events.emit('end-initialization');
            throw new Error('Pointer info is null on initializing an Entity !');
        }
        this.deactivateGizmo();
        this.removeCurrentSelectMesh();

        this.meshesToInitialize = [entity.mesh];
        entity.mesh.entity = entity;

        // Select it
        this.selectMesh(entity.mesh);

        // Init drag position
        if (dragging) {
            this.initDrag(entity.position);
            events.emit('drag-new'); // Override dragging
            this.dragSelected(); // Update initial position to cursor
        }

        if (this.copying) {
            const path = EntityHelper.getEntityPath(entity);
            dataStore.addEntity(entity, path);
        }
    }

    /**
     * Set the entityList position correctly, relative to the grid
     * @param {Array<Entity>} entityList
     */
    addGroupedObjectOnScene(entityList) {
        const meshList = entityList.map((entity) => {
            EntityController.fetchMesh(entity);
            return entity.mesh;
        });
        let bb = meshUtility.ComputeMeshListBB(meshList);
        const nodeMesh = new TransformNode('nodeMesh');
        nodeMesh.position = bb.centerWorld.clone();
        nodeMesh.computeWorldMatrix(true);
        entityList.forEach((entity) => {
            entity.mesh.computeWorldMatrix(true);
            entity.mesh.parent = nodeMesh;
        });
        bb = meshUtility.ComputeMeshListBB(meshList);
        if (bb.minimumWorld.y < -0.001) {
            nodeMesh.position.y += -bb.minimumWorld.y;
            nodeMesh.computeWorldMatrix(true);
            meshList.forEach((mesh) => {
                mesh.computeWorldMatrix(true);
            });
        }
        entityList.forEach((entity) => {
            entity.mesh.computeWorldMatrix(true);
            entity.mesh.setParent(null);
            entity.updateDynamicParameters();
        });
        nodeMesh.dispose();
        this.initGroupObject(entityList);
    }

    /**
     * Start the drag behavior of the given group of object
     * @param {Array<Entity>} entityList
     * @param {Boolean} dragging - launch the drag behavior after initialization
     */
    initGroupObject(entityList, dragging = true) {
        this.unselectAll();

        this.deactivateGizmo();
        this.removeCurrentSelectMesh();

        this.meshesToInitialize = [];
        entityList.forEach((entity) => {
            this.meshesToInitialize.push(entity.mesh);
            entity.mesh.entity = entity;
        });

        // Select them (without checking groups)
        this.selectMeshesNoGroup(entityList);

        // Init drag position
        if (dragging) {
            const bb = meshUtility.ComputeMeshListBB(this.selectedMeshes);
            this.initDrag(bb.centerWorld);
            events.emit('drag-new'); // Override dragging
            this.dragSelected(); // Update initial position to cursor
        }

        entityList.forEach((entity) => {
            const path = EntityHelper.getEntityPath(entity);
            dataStore.addEntity(entity, path);
        });
    }

    /**
     * Remove this entity's mesh from the selectedMesh array and dispose the selectMesh
     * @param {*} entity
     */
    removeOneMultiSelectMesh(entity) {
        this.deactivateGizmo();
        const { selectMesh } = entity.mesh;
        const index = this.selectedMeshes.indexOf(selectMesh);
        if (index >= 0) {
            this.selectedMeshes.splice(index, 1);
            SelectionHelper.removeSelectMesh(entity);
        }
        entity.freeze();
    }

    /**
     * Dispose the copy of the original selected mesh
     */
    removeCurrentSelectMesh() {
        if (this.selectedMeshes.length !== 1) {
            return;
        }

        if (this.selectedMeshes[0].originalMesh) {
            geometryUtility.toggleMeshVisibility(this.selectedMeshes[0].originalMesh, true, true);
        }

        const currentEntity = this.selectedMeshes[0].entity;
        SelectionHelper.removeSelectMesh(currentEntity);
        currentEntity.freeze();
        this.selectedMeshes.length = 0;
        this._highlightBehavior = null;
        events.emit('unselect-entity');
    }

    /**
     * Dispose the current entity
     */
    removeCurrentEntity() {
        if (this.selectedMeshes.length === 1) {
            this.selectedMeshes[0].checkCollisions = false;
            const entityToRemove = this.selectedMeshes[0].entity;
            this.unselectEntity();
            return new Promise((resolve) => {
                const onDispose = (entity) => {
                    if (entityToRemove.id === entity.id) {
                        events.removeListener('@entity-manager.mesh-disposed', onDispose);
                        resolve();
                    }
                };
                events.on('@entity-manager.mesh-disposed', onDispose);
                dataStore.removeEntity(entityToRemove);
            });
        }
        return new Promise(null);
    }

    /**
     * Show the + sprites allowing duplication of the current entity (see DuplicateControl class)
     * Remove gizmos if needed
     */
    addControlToCurrentEntity() {
        if (this.selectedMeshes.length !== 1) {
            return;
        }
        eventBus.emit('activating-tool', 'duplicate');

        const currentEntity = this.selectedMeshes[0].entity;

        this.duplicationActive = true;
        const leftPlus = new DuplicateControl('leftPlus', this.spriteManagerDuplicate, currentEntity, new Vector3(1, 0, 0), this.scene);
        const rightPlus = new DuplicateControl('rightPlus', this.spriteManagerDuplicate, currentEntity, new Vector3(-1, 0, 0), this.scene);
        const upPlus = new DuplicateControl('upPlus', this.spriteManagerDuplicate, currentEntity, new Vector3(0, 1, 0), this.scene);
        const downPlus = new DuplicateControl('downPlus', this.spriteManagerDuplicate, currentEntity, new Vector3(0, -1, 0), this.scene);
        const frontPlus = new DuplicateControl('frontPlus', this.spriteManagerDuplicate, currentEntity, new Vector3(0, 0, 1), this.scene);
        const backPlus = new DuplicateControl('backPlus', this.spriteManagerDuplicate, currentEntity, new Vector3(0, 0, -1), this.scene);

        currentEntity.controls = [leftPlus, rightPlus, upPlus, downPlus, frontPlus, backPlus];
    }

    /**
     * Set the infill image to all selected entities
     * @param {String} urlObject
     */
    addInfillImageToSelectedEntities(urlObject, infillType) {
        if (!this.selectedMeshes.length) {
            return;
        }
        if (this.selectedMeshes.length === 1) {
            OptionController.infillController.setInfillFromUrlObject(urlObject, [this.selectedMeshes[0].entity], infillType, true);
        } else {
            const selectedEntities = this.selectedMeshes.map((selectedMesh) => selectedMesh.entity);
            OptionController.infillController.setInfillFromUrlObject(urlObject, selectedEntities, infillType, true);
        }
    }

    displayImageOnEverySelectedEntities(image) {
        if (this.selectedMeshes.length < 2) {
            return;
        }
        events.emit('displaying-image-on-many-infills');
        const selectedEntities = this.selectedMeshes.map((selectedMesh) => selectedMesh.entity);
        OptionController.infillController.displayOneImageOnManyInfills(selectedEntities, image);
    }

    /**
     * Set the infill color to all selected entities
     * @param {String} color
     */
    addInfillColorToSelectedEntities(color, infillType) {
        if (!this.selectedMeshes.length) {
            return;
        }
        if (this.selectedMeshes.length === 1) {
            OptionController.infillController.setInfill([this.selectedMeshes[0].entity], { color }, infillType);
        } else {
            const selectedEntities = this.selectedMeshes.map((selectedMesh) => selectedMesh.entity);
            OptionController.infillController.setInfill(selectedEntities, { color }, infillType);
        }
    }

    /**
     * Remove the + sprites allowing duplication of the current entity
     */
    removeControlToCurrentEntity() {
        if (this.selectedMeshes.length !== 1 || !this.selectedMeshes[0].entity.controls) {
            return;
        }
        this.selectedMeshes[0].entity.controls.forEach((control) => {
            control.removeEvents();
            control.dispose();
        });
        this.selectedMeshes[0].entity.controls = null;
        this.duplicationActive = false;
    }

    /**
     * Return the number of selected objects
     */
    getSelectedObjectsLength() {
        return this.selectedMeshes.length;
    }

    /**
     * Change the selected mesh visibility state
     * @param {*} isVisible
     */
    toggleSelectMeshVisibility(isVisible = true, toggleOptions = true) {
        let selectMesh = null;
        if (this.meshesToInitialize) {
            selectMesh = this.meshesToInitialize.length === 1 ? this.meshesToInitialize[0].selectMesh : null;
        } else {
            selectMesh = this.selectedMeshes.length === 1 ? this.selectedMeshes[0] : null;
        }
        if (!selectMesh) {
            return;
        }
        geometryUtility.toggleMeshVisibility(selectMesh, isVisible, toggleOptions);
    }

    /**
     * Called on snap event
     * Select the meshes entity as the current entity
     * @param {AbstractMesh} meshToSelect
     */
    onSnap(meshToSelect) {
        if (!this.meshesToInitialize) {
            this.selectEntity(meshToSelect, true);
        }
    }

    /**
     * Returns the current selection bounding box
     */
    getSelectionBb() {
        if (this.selectedMeshes.length === 1) {
            return this.selectedMeshes[0].entity.getBoundingBox();
        }
        if (this.selectedMeshes.length) {
            return meshUtility.ComputeMeshListBB(this.selectedMeshes);
        }
        return null;
    }

    /**
     * Creates a TransformNode used for multiselected object interaction
     */
    initNodeMesh() {
        this.nodeMesh = new TransformNode('nodeMesh');
        if (this.selectedMeshes.length === 1) {
            const mesh = this.selectedMeshes[0];
            this.nodeMesh.position = mesh.position.clone();
            this.nodeMesh.rotationQuaternion = mesh.rotationQuaternion.clone();
        } else {
            const bb = meshUtility.ComputeMeshListBB(this.selectedMeshes);
            this.nodeMesh.position = bb.centerWorld.clone();
            this.nodeMesh.computeWorldMatrix(true);
        }

        this.selectedMeshes.forEach((mesh) => {
            const originalPos = mesh.position.clone();
            mesh.parent = this.nodeMesh;
            mesh.computeWorldMatrix(true);
            mesh.setAbsolutePosition(originalPos);

            if (this.selectedMeshes.length === 1) {
                mesh.rotationQuaternion = Quaternion.Zero();
            }

            const instance = mesh.entity.mesh;
            instance.parent = mesh;
            instance.position = Vector3.Zero();
            instance.rotationQuaternion = Quaternion.Zero();
            instance.rotationQuaternion.w = 1;
        });
    }

    /**
     * Destroys the TransformNode used for multiselected object interaction
     */
    destroyNodeMesh() {
        if (this.nodeMesh) {
            this.selectedMeshes.forEach((mesh) => {
                mesh.computeWorldMatrix(true);
                mesh.setParent(null);
                const instance = mesh.entity.mesh;
                instance.setParent(null);
                instance.position = mesh.position;
                instance.rotationQuaternion = mesh.rotationQuaternion;
            });
            this.nodeMesh.dispose();
            this.nodeMesh = null;
        }
    }

    /**
     * Called on CTRL + C and check if current selection is a single selection or a multiselection
     * @param {Boolean} dragging - Is the drag behavior activated after the copy
     * @returns {Entity[]}
     */
    copyCurrentSelection(dragging = true) {
        if (this.copying) {
            // Prevents copy during copy
            return [];
        }
        this.deactivateGizmo();
        if (this.selectedMeshes.length === 1) {
            const copy = this.copyCurrentEntity(dragging);
            return copy ? [copy] : [];
        }
        if (this.selectedMeshes.length) {
            return this.copyMultiSelectedEntities(dragging);
        }
        return [];
    }

    rotateCurrentSelection(radians, axis) {
        this.deactivateGizmo();
        if (this.selectedMeshes.length) {
            this.initNodeMesh();
        }
        const updatePositionCallback = (mesh) => {
            mesh.computeWorldMatrix(true);
            mesh.entity.updateDynamicParameters();
        };
        let rotAxis = Vector3.Up();
        if (axis === 1) {
            rotAxis = Vector3.Left();
        } else if (axis === 2) {
            rotAxis = Vector3.Forward();
        }
        if (this.nodeMesh) {
            this.nodeMesh.rotate(rotAxis, radians, Space.LOCAL);
            this.selectedMeshes.forEach(updatePositionCallback);
            this.destroyNodeMesh();
            history.snapshot();
        } else if (this.selectedMeshes.length === 1) {
            this.selectedMeshes[0].rotate(rotAxis, radians, Space.LOCAL);
            updatePositionCallback(this.selectedMeshes[0]);
            history.snapshot();
        }
    }

    /**
     * Called when we copy a single entity
     * Set a mesh to copy below the cursor
     * @returns {Entity}
     */
    copyCurrentEntity(dragging = true) {
        if (this.selectedMeshes.length !== 1) {
            return null;
        }
        this.copying = true;
        const newEntity = this.selectedMeshes[0].entity.clone();
        newEntity.group = this.selectedMeshes[0].entity.group;
        EntityController.fetchMesh(newEntity);
        this.initializingEntityOnScene(newEntity, { x: this.scene.pointerX, y: this.scene.pointerY }, dragging);
        return newEntity;
    }

    /**
     * Called when we copy a multiple selection
     * @returns {Entity[]}
     */
    copyMultiSelectedEntities(dragging = true) {
        // Clone every entities selected
        const copiedEntities = this.selectedMeshes.map((mesh) => {
            const clonedEntity = mesh.entity.clone();
            clonedEntity.group = mesh.entity.group;
            EntityController.fetchMesh(clonedEntity);
            return clonedEntity;
        });
        this.copying = true;
        this.initGroupObject(copiedEntities, dragging);
        return copiedEntities;
    }

    /**
     * Called when we press CTRL+V and we have a single copy
     * Duplicates the mesh under the pointer
     */
    pasteCurrentCopy() {
        if (!this.copying || !this.meshesToInitialize) {
            return;
        }
        history.snapshot(); // We snapshot before cloning, that way when we cancel selected mesh will become the clone

        let newEntity;
        this.meshesToInitialize.forEach((mesh) => {
            const currentSelectMesh = mesh.selectMesh;
            let position;
            // In case of magnetism
            if (currentSelectMesh.entity.ghost && currentSelectMesh.entity.ghost.snapped) {
                // We force position to magnetism
                position = currentSelectMesh.entity.ghost.mesh.getAbsolutePosition().clone();
                currentSelectMesh.entity.unghost();
            } else {
                position = currentSelectMesh.getAbsolutePosition().clone();
            }
            newEntity = currentSelectMesh.entity.clone({ position });
            const path = EntityHelper.getEntityPath(newEntity);
            EntityController.fetchMesh(newEntity);
            dataStore.addEntity(newEntity, path);
        });
        if (this.meshesToInitialize.length === 1) {
            events.emit('entity-pasted', newEntity);
        }
    }

    /**
     * Show/Hide every entity except current selection
     * @param {Boolean} visibility - default true
     */
    toggleEveryEntityVisibilityExceptSelection(visibility = true) {
        const entitiesSelectedIds = this.selectedMeshes.map((selectMesh) => selectMesh.entity.id);
        const entityList = dataStore
            .listEntities('/products/**')
            .concat(dataStore.listEntities('/connectors/**'))
            .filter((entity) => entitiesSelectedIds.indexOf(entity.id) === -1);
        entityList.forEach((entity) => {
            geometryUtility.toggleMeshVisibility(entity.mesh, visibility);
        });
        return entityList;
    }

    toggleSelectionHighlight(visibility = true) {
        this.selectedMeshes.forEach((selectMesh) => {
            highlightManager.toggleHighlightMesh(selectMesh, visibility);
        });
    }

    /**
     * Return a screenshot of the current selection
     */
    async getCurrentSelectionScreenshot(download = false, resolution = null, callback = () => {}) {
        const target = this.getSelectionBb().centerWorld;
        const otherEntities = this.toggleEveryEntityVisibilityExceptSelection(false);
        otherEntities.forEach((ent) => {
            ent.mesh.isPickable = false;
        });
        // hide measurements and do the screenshot
        measurementController.hideAll();

        const screenshot = await new Promise((resolve) => {
            setTimeout(() => {
                ScreenshotController.screenshotMeshList(this.selectedMeshes, target, resolution, callback).then((image) => {
                    this.toggleEveryEntityVisibilityExceptSelection();
                    otherEntities.forEach((ent) => {
                        ent.mesh.isPickable = true;
                    });
                    measurementController.showAll();
                    if (download) {
                        const link = document.createElement('a');
                        link.download = 'img.png';
                        link.href = URL.createObjectURL(image);
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve(image);
                });
            }, 300);
        });

        return screenshot;
    }

    /*
    ////////////////////////////////////////////////////////////////
    ///////////////////// GIZMO LOGIC //////////////////////////////
    ////////////////////////////////////////////////////////////////
    */

    showSelectMeshPositionGizmo() {
        const isActive = Boolean(gizmoController.positionGizmo.attachedMesh);
        this.deactivateGizmo();
        if (!isActive) {
            this.removeControlToCurrentEntity();
            if (this.selectedMeshes.length) {
                this.initNodeMesh();
                gizmoController.showPositionGizmo(this.nodeMesh);
                gizmoController.showPlaneGizmo(this.nodeMesh);
            }
        }
    }

    showSelectMeshPlaneGizmo() {
        gizmoController.showPlaneGizmo(this.selectedMeshes[0]);
    }

    showSelectMeshScaleGizmo() {
        if (this.selectedMeshes.length !== 1) {
            return;
        }
        const isActive = Boolean(gizmoController.scaleGizmo.attachedMesh);
        this.deactivateGizmo();
        if (!isActive) {
            gizmoController.showScaleGizmo(this.selectedMeshes[0]);
        }
    }

    showSelectMeshRotationGizmo() {
        const isActive = Boolean(gizmoController.rotationGizmo.attachedMesh);
        this.deactivateGizmo();

        if (!isActive) {
            this.removeControlToCurrentEntity();
            if (this.selectedMeshes.length) {
                this.initNodeMesh();
                gizmoController.showRotationGizmo(this.nodeMesh);
            }
        }
    }

    deactivateGizmo() {
        if (gizmoController.isGizmoActive()) {
            gizmoController.hideGizmos();
            if (this.nodeMesh) {
                this.destroyNodeMesh();
            }
        }
    }

    /**
     * @returns {Boolean}
     */
    hasSelection() {
        return Boolean(this.selectedMeshes.length);
    }

    toggleDuplication() {
        if (this.selectedMeshes.length !== 1) {
            return;
        }
        this.deactivateGizmo();
        if (!this.duplicationActive) {
            this.addControlToCurrentEntity();
        } else {
            this.removeControlToCurrentEntity();
        }
    }

    /**
     * Select nearby connectors
     * Used to screenshot group of connectors
     */
    selectNearbyConnectors() {
        const connectors = dataStore.listEntities('/connectors/*');
        const minDist = 0.2;
        const sqrMinDist = minDist * minDist;
        const groupEnts = [this.selectedMeshes[0].entity];
        connectors.forEach((entity) => {
            if (entity === this.selectedMeshes[0].entity) {
                return;
            }
            const selectEnt = groupEnts
                .map((gE) => Vector3.DistanceSquared(entity.mesh.position, gE.mesh.position))
                .find((dist) => dist <= sqrMinDist);
            if (selectEnt !== undefined) {
                groupEnts.push(entity);
                this.multiSelectMesh(entity);
            }
        });
    }
}
