import EntitiesData from '../../../../entities-data';
import SwapGui from '../gui/swap-gui';
import self from '../..';
import { Vector3, Quaternion, Axis, Matrix, StandardMaterial, Color3, Space, MeshBuilder } from '@babylonjs/core';

const {
    modules: {
        collisionManager: { CollisionHelper },
        obsidianEngine: { controller: engineController },
        catalogManager,
    },
    events,
    log,
} = self.app;

let resizableConstraints = null;

const EPSILON_GRID = -0.12; // This is used for the swapping
// Sometimes the placeholder grow a little in the opposite axis we are dragging
// so we set an epsilon to avoid to have a collision with the ground everytime

const EPSILON_TRANSLATE = 0.001; // This is used when we translate the new placeholder
// while we are rescaling it

const SWAP_STEP = {
    STRAIGHT: 0.062,
    CURVED: 0.496,
    PERFECT: 0.062,
    GLASS: 0.062,
    CORNER: 0.062,
    COVER: 0.062,
    SIDELED: 0.062,
    BACKLED: 0.496,
    STRUCTURAL: 0.062,
};

// For ref array
// ["PRODUIT(0) WIDTH(1) HEIGHT(2) DETAIL(3"]
const AXES_INDEX_ON_REF_ARRAY = {
    x: 1,
    y: 2,
};

export default class SwapController {
    constructor() {
        this.gizmoController = self.app.modules.gizmoManager.controller;
        this.collisionController = self.app.modules.collisionManager.Controller;
        this.meshUtility = self.app.modules.meshManager.meshUtility;
        this.products = self.app.modules.catalogManager.products;
        this.swapVue = new SwapGui();

        if (engineController.ready) {
            this.scene = engineController.scene;
            this.initPlaceHolderMaterials();
        } else {
            events.on('@obsidian-engine.ready', (scene) => {
                this.scene = scene;
                this.initPlaceHolderMaterials();
            });
        }

        // Initialize the datas for the swapping logic
        this.scaleGizmo = this.gizmoController.scaleGizmo;
        this.placeHolder = null;
        this.initResizeResponse();
        this.initScaleGizmoBehaviors();
        this.initEvents();
    }

    initResizeResponse() {
        this.resizeResponse = {
            instanciate: false,
            rotate: {
                value: false,
                axis: new Vector3(),
            },
            setToCorrectStepInNextSnap: false,
            diffVector: null,
            refToLoad: {
                height: null,
                width: null,
                depth: null,
            },
        };
        this.swapVue.changeDisplayedReference('');
    }

    initPlaceHolderMaterials() {
        this.placeHolderDefaultMaterial = new StandardMaterial('placeholder-material', this.scene);
        this.placeHolderDefaultMaterial.diffuseColor = Color3.Green();
        this.placeHolderDefaultMaterial.ambientColor = Color3.White().scale(0.2);
        this.placeHolderDefaultMaterial.alpha = 0.5;

        this.placeHolderErrorMaterial = this.placeHolderDefaultMaterial.clone();
        this.placeHolderErrorMaterial.diffuseColor = Color3.Red();
        this.placeHolderErrorMaterial.ambientColor = Color3.White().scale(0.2);
    }

    initScaleGizmoBehaviors() {
        this.initStartDragBehavior();
        this.initDragBehavior();
        this.initSnapBehavior();
        this.initDragEndBehavior();
    }

    /**
     * For each gizmo of the global scale gizmo we initialize the dragStartBehavior
     * which initialize the placeholder informations
     */
    initStartDragBehavior() {
        this.scaleGizmo.gizmos.forEach((gizmo) => {
            gizmo.dragBehavior.onDragStartObservable.add(() => {
                const cancelSwap = () => {
                    this.abortSwapping(gizmo);
                };
                const onceCallback = () => {
                    cancelSwap();
                    events.removeListener('@obsidian-engine.unselect', onceCallback);
                    events.removeListener('@obsidian-engine.copy', onceCallback);
                };
                events.on('@obsidian-engine.unselect', onceCallback);
                events.on('@obsidian-engine.copy', onceCallback);
                gizmo.dragBehavior.onDragEndObservable.add(() => {
                    events.removeListener('@obsidian-engine.unselect', onceCallback);
                    events.removeListener('@obsidian-engine.copy', onceCallback);
                });
                if (gizmo.attachedMesh) {
                    // Hide the original mesh
                    self.app.modules.geometryUtility.toggleMeshVisibility(gizmo.attachedMesh, false);
                    self.app.modules.optionManager.optionController.toggleOptionsVisibility(false, {
                        entity: gizmo.attachedEntity,
                    });
                    // Create a placeholder from the original bounding box
                    gizmo.attachedEntity.mesh.refreshBoundingInfo();
                    const { boundingBox } = gizmo.attachedEntity;
                    const size = boundingBox.maximum.subtract(boundingBox.minimum);
                    // Avoid collision with the placeholder by reducing the size by 0.01 if possible
                    this.placeHolder = MeshBuilder.CreateBox(
                        'scalePlaceholder',
                        {
                            height: size.y > 0.01 ? size.y - 0.01 : size.y,
                            width: size.x > 0.01 ? size.x - 0.01 : size.x,
                            depth: size.z,
                        },
                        this.scene,
                    );
                    this.placeHolder.material = this.placeHolderDefaultMaterial;
                    this.placeHolder.rotationQuaternion = gizmo.attachedMesh.rotationQuaternion.clone();
                    this.placeHolder.position = boundingBox.centerWorld.clone();
                    this.placeHolder.computeWorldMatrix(true);
                    this.swapInfos = {
                        startingPosition: gizmo.attachedMesh.position.clone(),
                        startingRef: gizmo.attachedEntity.ref,
                        startingEntity: gizmo.attachedEntity,
                        currentDelta: 0,
                        nextRef: null,
                        nextRefSwapped: null,
                    };
                    if (gizmo.attachedMesh.subCollidersBB && gizmo.attachedMesh.subCollidersBB.length) {
                        this.initialSubBBs = gizmo.attachedMesh.subCollidersBB;
                        this.initSubBbsPosition(gizmo.attachedMesh);
                    }
                    const placeHolderBb = this.placeHolder.getBoundingInfo().boundingBox;
                    // Compute a size factor which will be multiplied by the rescaled size
                    // on snap (step behavior) end
                    this.sizeFactor = this.placeHolder.scaling.divide(placeHolderBb.extendSize.scale(2));
                    this.scaleGizmo.currentSize = placeHolderBb.extendSize.scale(2);

                    this.swapVue.showLabel(this.placeHolder);

                    const { newSize } = SwapController.getNextSizeWithAxis(gizmo.dragAxis, size);

                    this.swapVue.changeDisplayedReference(newSize.toString());
                }
            });
            // Set the snapDistance depending of the frameType of gizmo.attachedEntity
            gizmo.dragBehavior.onDragStartObservable.add(() => {
                const entityType = EntitiesData.entityTypeFromSubCategory(gizmo.attachedEntity.subCategory, gizmo.attachedEntity.ref);
                const step = SwapController.getEntityTypeData(entityType, SWAP_STEP);
                gizmo.snapDistance = step;
                const originalDimension = self.app.modules.meshManager.meshController.getDimensionsFromRef(
                    this.swapInfos.startingRef,
                    entityType,
                );
                let fixedDimension = new Vector3(originalDimension.width, originalDimension.height, originalDimension.depth);
                if (entityType === EntitiesData.ENTITY_TYPE.COVER && !SwapController.isCoordinatesInCorrectStep(fixedDimension, step)) {
                    fixedDimension = SwapController.setDimensionToCorrectStep(fixedDimension, step);
                    this.resizeResponse.setToCorrectStepInNextSnap = true;
                }
                this.swapInfos.startingDimension = fixedDimension;
            });
        });
    }

    /**
     * For mesh that has subBBs, we clone them and translate them to the correct mesh center
     * @param {*} mesh
     */
    initSubBbsPosition(mesh) {
        const subBBClone = this.meshUtility.CloneSubColliders(mesh.subCollidersBB);

        let diff = mesh.position.subtract(mesh.getBoundingInfo().boundingBox.centerWorld);
        const matrix = new Matrix();
        mesh.rotationQuaternion.toRotationMatrix(matrix);
        diff = Vector3.TransformCoordinates(diff, matrix.invert());
        subBBClone.forEach((subCollider, index) => {
            subBBClone[index] = subCollider.map((vec) => vec.add(diff));
        });
        this.placeHolder.subCollidersBB = subBBClone;
    }

    /**
     * For each gizmo of the global scale gizmo we initialize the dragBehavior
     * which initialize the snap of 0.62.
     * (not to confuse with BEMATRIX snap logic, in BABYLON snap is when we are dragging
     *  every n step, here n = 0.62)
     */
    initDragBehavior() {
        this.scaleGizmo.gizmos.forEach((gizmo) => {
            gizmo.dragBehavior.onDragObservable.clear();
            let currentSnapDragDistance = 0;
            const tmpVector = new Vector3();
            const tmpSnapEvent = { snapDistance: 0 };
            gizmo.dragBehavior.onDragObservable.add((event) => {
                // Babylon initial behavior of scale gizmo
                if (gizmo.attachedMesh) {
                    // Step logic
                    let snapped = false;
                    let dragSteps = 0;
                    if (gizmo.uniformScaling) {
                        gizmo.attachedMesh.scaling.normalizeToRef(tmpVector);
                        if (tmpVector.y < 0) {
                            tmpVector.scaleInPlace(-1);
                        }
                    } else {
                        tmpVector.copyFrom(gizmo.dragAxis);
                    }
                    if (gizmo.snapDistance === 0) {
                        tmpVector.scaleToRef(event.dragDistance, tmpVector);
                    } else {
                        currentSnapDragDistance += event.dragDistance;
                        if (Math.abs(currentSnapDragDistance) > gizmo.snapDistance) {
                            dragSteps = Math.trunc(currentSnapDragDistance / gizmo.snapDistance);
                            currentSnapDragDistance %= gizmo.snapDistance;
                            tmpVector.scaleToRef(gizmo.snapDistance * dragSteps, tmpVector);
                            snapped = true;
                        } else {
                            tmpVector.scaleInPlace(0);
                        }
                    }
                    if (snapped) {
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                        gizmo.onSnapObservable.notifyObservers(tmpSnapEvent);
                    }
                }
            });
        });
    }

    /**
     * For each gizmo of the global scale gizmo we initialize the snapBehavior
     * which happen every time we drag with a step of 0.62
     */
    initSnapBehavior() {
        // Relay snap events
        this.scaleGizmo.gizmos.forEach((gizmo) => {
            gizmo.onSnapObservable.add((eventData) => {
                // Get the step of snapping
                const entityType = EntitiesData.entityTypeFromSubCategory(gizmo.attachedEntity.subCategory, gizmo.attachedEntity.ref);
                const correctStep = SwapController.getEntityTypeData(entityType, SWAP_STEP);
                const snapDistance = SwapController.roundStepValue(eventData.snapDistance, correctStep);

                // We add the snap distance to the final delta
                // to add to check our ref at drag end
                const tmpDelta = this.swapInfos.currentDelta + snapDistance;
                this.swapInfos.currentDelta = SwapController.roundStepValue(tmpDelta, correctStep);

                // DEBUG : We display the bb to see if the real bounding box is coherent
                // this.placeHolder.showBoundingBox = true;

                // We check if we can instanciate a frame or not
                this.checkplaceHolderResponse(gizmo.dragAxis, correctStep, entityType);
                if (!this.resizeResponse.instanciate) {
                    // We have a red material in case we can't instanciate the frame
                    this.placeHolder.material = this.placeHolderErrorMaterial;
                } else {
                    this.placeHolder.material = this.placeHolderDefaultMaterial;
                }
                // We multiply the snapDistance by the absolute
                // value of the axis we are dragging
                const axis = gizmo.dragAxis.multiply(gizmo.dragAxis);

                // TODO: Don't know why this was for, but it looks weird with it (wrong size)
                /* // Check if we need to adapt the placeholder size to a correct step
                    if (entityType === EntitiesData.ENTITY_TYPE.COVER
                        && this.resizeResponse.setToCorrectStepInNextSnap) {

                        const diffVector = Vector3.Zero();
                        const tempSize = SwapController.setDimensionToCorrectStep(
                            this.scaleGizmo.currentSize, correctStep
                        );
                            // check the fixed dimension
                        tempSize.multiply(axis);
                        const axisCoordinate = ["x", "y", "z"].find((
                            coordinate => (tempSize[coordinate] !== 0)
                        ));
                        if (axisCoordinate) {
                            diffVector[axisCoordinate] = this.scaleGizmo
                                .currentSize[axisCoordinate] - tempSize[axisCoordinate];
                            this.scaleGizmo.currentSize[axisCoordinate] = tempSize[
                                axisCoordinate];
                            this.resizeResponse.diffVector = diffVector;
                        }
                    } */

                const delta = axis.scale(snapDistance);

                // CurrentSize is local to the placeholder, we add the delta to it
                this.scaleGizmo.currentSize.addInPlace(delta);

                // We multiply the new size by the sizeFactor to get the new scaling value
                const newScaling = this.scaleGizmo.currentSize.multiply(this.sizeFactor);

                // We set the new placeholder scaling
                this.placeHolder.scaling = newScaling;
                this.placeHolder.computeWorldMatrix(true);

                // We compute a deltaTranslate wich translate the placeholder
                // The deltaTranslate is in the placeholder base (But not scaled)
                const deltaTranslateWithEpsilon = gizmo.dragAxis.scale(snapDistance / 2 + EPSILON_TRANSLATE); // We use an epsilon for collision management

                // we take account of the diff if the dimension was changed to adapt to a step
                if (this.resizeResponse.setToCorrectStepInNextSnap) {
                    if (this.resizeResponse.diffVector) {
                        deltaTranslateWithEpsilon.subtractInPlace(this.resizeResponse.diffVector.scale(1 / 2));
                    }
                    this.resizeResponse.setToCorrectStepInNextSnap = false;
                }

                const deltaTranslateReverseEpsilon = gizmo.dragAxis.scale(-EPSILON_TRANSLATE);

                // We divide the deltaTranslate by the scaling value because the
                // local value of the placeholder position are scaled
                deltaTranslateWithEpsilon.divideInPlace(this.placeHolder.scaling);
                deltaTranslateReverseEpsilon.divideInPlace(this.placeHolder.scaling);
                this.placeHolder.locallyTranslate(deltaTranslateWithEpsilon);
                this.placeHolder.computeWorldMatrix(true);

                // Subtract the epsilon
                this.placeHolder.locallyTranslate(deltaTranslateReverseEpsilon);
                this.placeHolder.computeWorldMatrix(true);
            });
        });
    }

    initDragEndBehavior() {
        this.scaleGizmo.gizmos.forEach((gizmo) => {
            gizmo.dragBehavior.onDragEndObservable.add(() => {
                const originalRef = this.swapInfos.startingRef;
                if (this.resizeResponse.instanciate && originalRef) {
                    // For the loader, we block the user interaction while loading the new model
                    events.emit('loading-swap-model');

                    const { nextRef, nextRefSwapped } = this.swapInfos;

                    const { ref } = this.products[nextRef] || this.products[nextRefSwapped];

                    // Adding the new model to the scene
                    try {
                        self.app.modules.projectManager.controller
                            .addToScene(ref)
                            .then((struct) => {
                                self.app.modules.groupManager.Controller.addEntityToGroup(struct, gizmo.attachedEntity.group);
                                // Keep the color of the old struct if needed
                                if (gizmo.attachedEntity.isColorable) {
                                    struct.color = gizmo.attachedEntity.color;
                                }
                                struct.rotation = this.placeHolder.rotation;
                                struct.rotationQuaternion = this.placeHolder.rotationQuaternion;
                                let deltaTranslate = gizmo.dragAxis.scale(this.swapInfos.currentDelta / 2);
                                if (this.resizeResponse.diffVector) {
                                    deltaTranslate.subtractInPlace(this.resizeResponse.diffVector.scale(1 / 2));
                                    this.resizeResponse.diffVector = null;
                                }
                                // In case we need to rotate the model
                                if (this.resizeResponse.rotate.value) {
                                    struct.mesh.rotate(Axis.Z, Math.PI / 2, Space.LOCAL);
                                    struct.mesh.computeWorldMatrix(true);
                                    const rotationMatrix = new Matrix();
                                    Quaternion.RotationAxis(Axis.Z, -Math.PI / 2).toRotationMatrix(rotationMatrix);
                                    deltaTranslate = Vector3.TransformCoordinates(deltaTranslate, rotationMatrix);
                                }

                                // We put the model to the right position
                                struct.position = this.swapInfos.startingPosition;
                                struct.mesh.locallyTranslate(deltaTranslate);
                                struct.mesh.computeWorldMatrix(true);
                                if (struct.snappyRects) {
                                    struct.updateRectsMatrixes();
                                }

                                // Replace the model if it goes under the ground but with
                                // a min world below epsilonGrid
                                CollisionHelper.isUnderGrid(struct, { isEntity: true, respond: true });
                                struct.updatePosition();
                                struct.updateRotation();
                                self.app.modules.selectionManager
                                    .removeCurrentEntity()
                                    .then(() => {
                                        self.app.modules.selectionManager.selectMesh(struct.mesh);
                                        // It's the last step because we need to dispose
                                        // and recreate the mesh
                                        self.app.modules.history.snapshot();
                                        // Gizmo is lost during swapping, so add it back
                                        self.app.modules.selectionManager.showSelectMeshScaleGizmo();
                                    })
                                    .then(() => {
                                        // Remove placeholder
                                        this.endSwapping(this.scaleGizmo);
                                    })
                                    .catch((err) => {
                                        log.error(
                                            `${'ERROR WHILE LOADING REF: height: '}
                                                ${this.resizeResponse.refToLoad.height}
                                                width: ${this.resizeResponse.refToLoad.width}`,
                                            err,
                                        );
                                        this.manageError(gizmo);
                                    });
                            })
                            .catch((error) => {
                                log.error(
                                    `${'ERROR WHILE LOADING REF: height: '}
                                    ${this.resizeResponse.refToLoad.height}
                                    width: ${this.resizeResponse.refToLoad.width}`,
                                    error,
                                );
                                this.manageError(gizmo);
                            });
                    } catch (error) {
                        log.error(
                            `${'ERROR WHILE LOADING REF: height: '}
                            ${this.resizeResponse.refToLoad.height}
                            width: ${this.resizeResponse.refToLoad.width}`,
                            error,
                        );
                        this.manageError(gizmo);
                    }
                } else {
                    if (!originalRef) {
                        log.error('Cannot find original ref !', originalRef);
                    }
                    // You can't instanciate the frame
                    events.emit('loading-swap-model');
                    this.manageError(gizmo);
                }
            });
        });
    }

    initEvents() {
        events.on('@catalog-manager.catalog-initialized', () => {
            // eslint-disable-next-line prefer-destructuring
            resizableConstraints = catalogManager.resizableConstraints;
        });
        // Check if it's needed to display all the scale gizmo axis
        events.on('@gizmo-manager.show-gizmo', () => {
            this.scaleGizmo.gizmos.forEach((gizmo) => {
                if (!gizmo.attachedEntity) {
                    return;
                }
                const entityType = EntitiesData.entityTypeFromSubCategory(gizmo.attachedEntity.subCategory, gizmo.attachedEntity.ref);
                const minMaxSizes = SwapController.getEntityTypeData(entityType, resizableConstraints);
                const dragAxisAbs = gizmo.dragAxis.multiply(gizmo.dragAxis);

                if (dragAxisAbs.equals(Vector3.Up())) {
                    if (minMaxSizes.MIN_HEIGHT === minMaxSizes.MAX_HEIGHT) {
                        gizmo.attachedMesh = null;
                    }
                }
                if (dragAxisAbs.equals(Vector3.Right())) {
                    if (minMaxSizes.MIN_WIDTH === minMaxSizes.MAX_WIDTH) {
                        gizmo.attachedMesh = null;
                    }
                }
                if (dragAxisAbs.equals(Vector3.Forward())) {
                    if (minMaxSizes.MIN_DEPTH === minMaxSizes.MAX_DEPTH) {
                        gizmo.attachedMesh = null;
                    }
                }
            });
        });
    }

    /**
     * Destroy the placeholder and reinit the swapInfos
     */
    endSwapping() {
        this.swapVue.hideLabel();
        if (this.placeHolder) {
            this.placeHolder.dispose();
            this.placeHolder = null;
            this.swapInfos = {
                startingPosition: null,
                startingRef: null,
                currentDelta: 0,
            };
        }

        // Event to stop the loader
        events.emit('end-swap-model');
        this.initResizeResponse();
    }

    /**
     * Called when there was an error while you were swapping an object
     * @param {Gizmo} gizmo the current scale gizmo
     */
    manageError(gizmo) {
        self.app.modules.geometryUtility.toggleMeshVisibility(gizmo.attachedMesh, true);
        self.app.modules.optionManager.optionController.toggleOptionsVisibility(true, { entity: gizmo.attachedEntity });
        this.endSwapping();
    }

    abortSwapping(gizmo) {
        this.resizeResponse.instanciate = false;
        gizmo.dragBehavior.releaseDrag();
    }

    /**
     * Check if the swapping is valable or not by looking at the dimension of the
     * placeholder and the max dimensions setted
     * @param {Vector3} dragAxis the local axis of the gizmo which is dragged
     * @param {Number} correctStep
     * @param {*} entityType
     */
    checkplaceHolderResponse(dragAxis, correctStep, entityType) {
        // We get the original dimension by checking the original reference
        const axis = dragAxis.multiply(dragAxis);
        const toAdd = axis.scale(this.swapInfos.currentDelta);
        toAdd.set(
            SwapController.roundStepValue(toAdd.x, correctStep),
            SwapController.roundStepValue(toAdd.y, correctStep),
            SwapController.roundStepValue(toAdd.z, correctStep),
        );
        // The new dimension we are checking the response
        let newDimension = this.swapInfos.startingDimension.clone();
        if (this.resizeResponse.setToCorrectStepInNextSnap) {
            newDimension = SwapController.setDimensionToCorrectStep(newDimension, correctStep);
        }
        newDimension.addInPlace(toAdd);
        // We round the dimension to get correct values
        newDimension.set(newDimension.x.toFixed(3), newDimension.y.toFixed(3), newDimension.z.toFixed(3));
        // We check that the dimensions are not crossing the frames limit
        const isBeyondLimit = SwapController.isBeyondLimit(newDimension.x, newDimension.y, newDimension.z, entityType);
        if (isBeyondLimit) {
            this.initResizeResponse();
            return;
        }

        const currentRef = SwapController.parseRef(this.swapInfos.startingRef, newDimension.y, newDimension.x, newDimension.z, entityType);

        // Check if next product generated exist on the catalog products
        const { newSize, axisProperty } = SwapController.getNextSizeWithAxis(dragAxis, newDimension);
        this.addNextProductRefToSwapInfos(currentRef, axisProperty, newSize);
        const { nextRef, nextRefSwapped } = this.swapInfos;

        if (!this.products[nextRef] && !this.products[nextRefSwapped]) {
            this.initResizeResponse();
            return;
        }

        // We are checking the collision
        const isColliding = this.collisionController.checkStaticCollisionsMesh(this.placeHolder, currentRef);
        // Sometimes the mesh can resize itself in the oposite axis so we set an
        // epsilon
        const isBelowGrid = CollisionHelper.isUnderGrid(this.placeHolder, { epsilonGrid: EPSILON_GRID });
        if (isColliding || isBelowGrid) {
            this.initResizeResponse();
            return;
        }

        if (!SwapController.getEntityTypeData(entityType, resizableConstraints).ONE_AXIS) {
            // If the resize is on x and y axis, handle switching between those dimensions
            // and rotate the mesh to fit the available dimensions if needed
            const { max, minOfMax } = SwapController.getResizableMinMaxSizes(entityType);

            // When we are dragging in the x axis
            if (dragAxis.equals(Vector3.Left()) || dragAxis.equals(Vector3.Right())) {
                if (newDimension.x / 10 > minOfMax && newDimension.x / 10 <= max) {
                    if (newDimension.y / 10 <= minOfMax) {
                        this.setResponseToSuccess(newDimension, true, dragAxis);
                        return;
                    }
                } else if (newDimension.x / 10 <= minOfMax && newDimension.y / 10 <= minOfMax) {
                    if (newDimension.x / 10 > newDimension.y / 10) {
                        this.setResponseToSuccess(newDimension, true, dragAxis);
                        return;
                    }
                }
            }

            // When we are dragging in the y axis
            if (dragAxis.equals(Vector3.Up()) || dragAxis.equals(Vector3.Down())) {
                if (newDimension.y / 10 < newDimension.x / 10) {
                    this.setResponseToSuccess(newDimension, true, dragAxis);
                    return;
                }
            }
        }
        this.setResponseToSuccess(newDimension, false, dragAxis);
    }

    addNextProductRefToSwapInfos(currentRef, axisProperty, newSize) {
        const splittedRefValues = currentRef.split(' ');
        splittedRefValues[AXES_INDEX_ON_REF_ARRAY[axisProperty]] = String(newSize).padStart(4, '0');

        // Rotate the ref, because size can exist in one axis, but it's the same product
        const splittedRefSwappedValues = [...splittedRefValues];
        splittedRefSwappedValues[1] = splittedRefValues[2];
        splittedRefSwappedValues[2] = splittedRefValues[1];

        this.swapInfos.nextRef = splittedRefValues.join(' ');
        this.swapInfos.nextRefSwapped = splittedRefSwappedValues.join(' ');
    }

    /**
     * Called when we can instanciate a new frame for swapping
     * take account of the axis we are dragging
     * @param {x, y} newDimension the dimension of the new frame
     * @param {Boolean} rotate true if the frame needs to be rotated
     * @param {Vector3} dragAxis the dragAxis if the frame needs
     * to be rotated
     */
    setResponseToSuccess(newDimension, rotate = false, dragAxis = new Vector3()) {
        this.resizeResponse.instanciate = true;
        this.resizeResponse.rotate.axis = dragAxis;
        this.resizeResponse.rotate.value = rotate;

        const dragAxisDimensions = dragAxis.multiply(newDimension);
        const axisProperty = ['x', 'y', 'z'].find((axis) => dragAxisDimensions[axis] !== 0);
        const newSize = Math.ceil(Math.abs(dragAxisDimensions[axisProperty] * 1000));

        // Update gui text
        this.swapVue.changeDisplayedReference(newSize.toString());

        if (rotate) {
            this.resizeResponse.refToLoad = {
                height: Math.max(newDimension.x, newDimension.y),
                width: Math.min(newDimension.x, newDimension.y),
                depth: newDimension.z,
            };

            return;
        }

        this.resizeResponse.refToLoad = {
            height: newDimension.y,
            width: newDimension.x,
            depth: newDimension.z,
        };
    }

    /**
     * Enables to create the string ref by giving the height, the width
     * and the type of the frame
     * Uses the orinal refrence to keep prefix and suffix of the ref
     * @param {String} originalRef
     * @param {Number} height
     * @param {Number} width
     * @param {ENTITY_TYPE} entityType
     */
    static parseRef(originalRef, height, width, depth, entityType = EntitiesData.ENTITY_TYPE.STRAIGHT) {
        const splittedRef = originalRef.split(' ');
        const prefix = splittedRef[0];
        let suffix = splittedRef[splittedRef.length - 1];
        const stringHeight = height.toString().replace('.', '');
        const paddedHeight = stringHeight.padEnd(4, '0');

        const stringWidth = width.toString().replace('.', '');
        const paddedWidth = stringWidth.padEnd(4, '0');

        const stringDepth = depth.toString().replace('.', '');
        const paddedDepth = stringDepth.padEnd(4, '0');

        if (entityType === EntitiesData.ENTITY_TYPE.GLASS) {
            return `${prefix} ${paddedWidth} ${paddedHeight}`;
        }

        if (entityType === EntitiesData.ENTITY_TYPE.PERFECT) {
            return `${prefix} ${paddedDepth} ${paddedHeight} ${suffix}`;
        }

        if (entityType === EntitiesData.ENTITY_TYPE.CORNER) {
            if (suffix === 'SET') {
                suffix = `${splittedRef[splittedRef.length - 2]} ${suffix}`;
            }
            return `${prefix} ${paddedDepth} ${suffix}`;
        }
        if (entityType === EntitiesData.ENTITY_TYPE.COVER || entityType === EntitiesData.ENTITY_TYPE.STRUCTURAL) {
            if (entityType === EntitiesData.ENTITY_TYPE.COVER && suffix !== 'D00') {
                return `${prefix} ${splittedRef[1]} ${paddedDepth}`;
            }
            return `${prefix} ${paddedDepth} ${suffix}`;
        }
        return `${prefix} ${paddedWidth} ${paddedHeight} ${suffix}`;
    }

    /**
     * Check if the given dimensions of the frame are not beyond the limit
     * in function of its type
     * @param {Number} x
     * @param {Number} y
     * @param {Number} z
     * @param {ENTITY_TYPE} entityType
     */
    static isBeyondLimit(x, y, z, entityType = EntitiesData.ENTITY_TYPE.STRAIGHT) {
        const { max, minOfMax, min, maxOfMin } = SwapController.getResizableMinMaxSizes(entityType);

        const xDivided = x / 10;
        const yDivided = y / 10;
        let zDivided = 0;
        if (z) {
            zDivided = z / 10;
        }
        const frameTypeData = SwapController.getEntityTypeData(entityType, resizableConstraints);
        // Classic test
        if (frameTypeData.MIN_HEIGHT && (yDivided > max || yDivided < min)) {
            return true;
        }
        if (frameTypeData.MIN_WIDTH && (xDivided > max || xDivided < min)) {
            return true;
        }
        if (frameTypeData.MIN_DEPTH && (zDivided < min || zDivided > max)) {
            return true;
        }

        const dimensions = Object.keys(frameTypeData)
            .filter((extremum) => extremum.startsWith('MIN'))
            .map((key) => key.split('_')[1]);

        if (dimensions.length <= 1) {
            return false;
        }

        let checkMinOfMax = false;
        let checkMaxOfMin = false;
        if (dimensions.includes('HEIGHT') && dimensions.includes('WIDTH')) {
            checkMinOfMax = checkMinOfMax || (xDivided > minOfMax && yDivided > minOfMax);
            checkMaxOfMin = checkMaxOfMin || (xDivided < maxOfMin && yDivided < maxOfMin);
        }

        if (dimensions.includes('HEIGHT') && dimensions.includes('DEPTH')) {
            checkMinOfMax = checkMinOfMax || (zDivided > minOfMax && yDivided > minOfMax);
            checkMaxOfMin = checkMaxOfMin || (zDivided < maxOfMin && yDivided < maxOfMin);
        }

        if (dimensions.includes('DEPTH') && dimensions.includes('WIDTH')) {
            checkMinOfMax = checkMinOfMax || (zDivided > minOfMax && xDivided > minOfMax);
            checkMaxOfMin = checkMaxOfMin || (zDivided < maxOfMin && xDivided < maxOfMin);
        }
        return checkMinOfMax || checkMaxOfMin;
    }

    /**
     * Round a value by a step
     * @param {Number} delta the value we want to round
     * @param {Number} step the step use
     */
    static roundStepValue(delta, step) {
        const firstValue = Math.round(delta / step) * step;
        return Math.round(firstValue * 1000) / 1000;
    }

    /**
     * Returns entityData's nested object who's key is entityType
     * @param {ENTITY_TYPE} entityType
     * @param {*} entityData object to get the data from
     * @return {{ MIN_HEIGHT, MIN_WIDTH, MAX_WIDTH, MAX_HEIGHT }}
     */
    static getEntityTypeData(entityType, entityData) {
        if (entityType) {
            return entityData[Object.keys(EntitiesData.ENTITY_TYPE).find((name) => name === entityType)];
        }
        return null;
    }

    /**
     * Returns min, max, minOfMax and maxOfMin of the entityType constant data
     * @param {*} entityType
     * @return {{ max, minOfMax, min, maxOfMin }}
     */
    static getResizableMinMaxSizes(entityType) {
        const frameTypeConstData = SwapController.getEntityTypeData(entityType, resizableConstraints);
        const maxValuesKeys = Object.keys(frameTypeConstData).filter((extremum) => extremum.startsWith('MAX'));
        const maxValues = maxValuesKeys.map((k) => frameTypeConstData[k]);
        const minValuesKeys = Object.keys(frameTypeConstData).filter((extremum) => extremum.startsWith('MIN'));
        const minValues = minValuesKeys.map((k) => frameTypeConstData[k]);
        return {
            max: Math.max(...maxValues),
            minOfMax: Math.min(...maxValues),
            min: Math.min(...minValues),
            maxOfMin: Math.max(...minValues),
        };
    }

    static setDimensionToCorrectStep(coordinates, step) {
        let newCoordinates = coordinates.clone();
        newCoordinates = newCoordinates.scale(1000);
        newCoordinates.x = Math.trunc(newCoordinates.x / (step * 1000));
        newCoordinates.y = Math.trunc(newCoordinates.y / (step * 1000));
        newCoordinates.z = Math.trunc(newCoordinates.z / (step * 1000));
        newCoordinates.scaleInPlace(step * 1000);
        return newCoordinates.scale(1 / 1000);
    }

    static isCoordinatesInCorrectStep(coordinates, step) {
        let newCoordinates = coordinates.clone();
        ['x', 'y', 'z'].forEach((coord) => {
            newCoordinates[coord] = newCoordinates[coord].toFixed(3);
        });
        newCoordinates = newCoordinates.scale(1000);
        return ['x', 'y', 'z'].every((dim) => dim % (step * 1000) === 0);
    }

    static getNextSizeWithAxis(dragAxis, newDimension) {
        const dragAxisDimensions = dragAxis.multiply(newDimension);
        const axisProperty = ['x', 'y', 'z'].find((_axis) => dragAxisDimensions[_axis] !== 0);
        const newSize = Math.ceil(Math.abs(dragAxisDimensions[axisProperty] * 1000));
        return { newSize, axisProperty };
    }
}
