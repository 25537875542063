<script lang="ts">
    import product from './catalog-product.vue';
    import EventBus from '../../eventBus';
    import self from '../../..';

    const {
        modules: { catalogManager },
    } = self.app;

    export default {
        name: 'Catalog',
        components: { product },
        props: {
            catalog: {
                type: Array,
                default: () => [],
            },
            config: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                currentProducts: [],
                currentCategory: null,
                currentSubcategory: null,
                currentlyHoveredSubcategory: null,
                initializing: false,
                subcategoryActiveIcon: '/assets/icons/select.svg',
                subcategoryInactiveIcon: '/assets/icons/un-select.svg',
                products: catalogManager.products,
            };
        },
        computed: {
            categoriesToDisplay() {
                return this.catalog.filter((categories) => categories.showInCatalog);
            },
            subcategoriesToDisplay() {
                return this.categoriesToDisplay[this.currentCategory].groups.filter((subcategories) => subcategories.showInCatalog);
            },
        },
        mounted() {
            EventBus.$on('hide-products', () => {
                this.hideProducts();
            });
            self.app.events.on('@selection-manager.end-initialization', () => {
                this.onEndInitializing();
            });
            // TODO : temporary to avoid left menu overlapping on bottom menu
            self.app.events.on('@selection-manager.select-entity', () => {
                if (!this.initializing) {
                    this.hideProducts();
                }
            });
            self.app.events.on('@project-manager.init-group-object', () => {
                this.onInitializing();
                self.app.events.once('@project-manager.end-init-group-object', () => {
                    this.onEndInitializing();
                });
            });
        },
        methods: {
            /**
             * Show the subcategories of the given category
             */
            toggleSubcategories(category) {
                this.hideProducts();
                this.currentCategory = this.currentCategory === category ? null : category;
            },

            thumbnailPath(productRef) {
                return `${this.config.thumbnailsUrl}/${this.products[productRef].thumbnailUrl}`;
            },

            /**
             * Show the products of the given category and subgategory
             */
            displayProducts(category, subcategory) {
                self.app.modules.selectionManager.unselectAll(); // TODO : temporary to avoid left menu
                // overlapping on bottom menu
                this.currentSubcategory = subcategory;
                this.currentProducts = this.showableProducts();
            },
            hideProducts() {
                this.currentProducts = [];
                this.currentSubcategory = null;
            },
            onInitializing() {
                this.initializing = true;
            },
            onEndInitializing() {
                this.initializing = false;
            },
            // Return the product that must be displayed
            showableProducts() {
                const { products } = this.subcategoriesToDisplay[this.currentSubcategory];
                return products.filter((currentProduct) => currentProduct.showInCatalog);
            },
            isCurrentCategory(categoryIndex) {
                return categoryIndex === this.currentCategory;
            },
            isCurrentSubcategory(subcategoryIndex) {
                return subcategoryIndex === this.currentSubcategory;
            },
            _(...args) {
                return self.app.modules.stonejs.gettext(...args);
            },
        },
    };
</script>

<template>
    <div>
        <ul id="category-menu">
            <li
                v-for="(category, categoryIndex) in categoriesToDisplay"
                :key="categoryIndex"
                class="category-item"
                :disabled="currentCategory === categoryIndex"
            >
                <label @click="toggleSubcategories(categoryIndex)">
                    <i
                        v-if="currentCategory != categoryIndex"
                        class="fas fa-angle-right"
                    />
                    <i
                        v-if="currentCategory === categoryIndex"
                        class="fas fa-angle-down"
                    />
                    <span>{{ _(category.name) }} &nbsp;</span>
                </label>
                <ul
                    v-if="currentCategory === categoryIndex"
                    id="subcategory-menu"
                >
                    <li
                        v-for="(subcategory, subcategoryIndex) in subcategoriesToDisplay"
                        :key="subcategoryIndex"
                        class="subcategory-item"
                        :class="{ active: currentSubcategory === subcategoryIndex }"
                        @click="currentSubcategory === subcategoryIndex ? hideProducts() : displayProducts(categoryIndex, subcategoryIndex)"
                        @mouseover="currentlyHoveredSubcategory = subcategoryIndex"
                        @mouseleave="currentlyHoveredSubcategory = null"
                    >
                        <img
                            v-if="currentSubcategory === subcategoryIndex || currentlyHoveredSubcategory === subcategoryIndex"
                            :src="subcategoryActiveIcon"
                        />
                        <img
                            v-else
                            :src="subcategoryInactiveIcon"
                        />
                        <span>&nbsp; {{ _(subcategory.name) }}</span>
                    </li>
                </ul>
            </li>
        </ul>
        <div
            v-if="currentSubcategory !== null"
            v-show="!initializing"
            class="products-menu"
        >
            <product
                v-for="product in currentProducts"
                :key="product.ref"
                :infos="product"
                :reference="product.ref"
                :name="product.name"
                :thumbnail-path="thumbnailPath(product.ref)"
                :is-available="product.isAvailable"
                :is-raw-thumbnail-path="true"
                @mesh-initializing="onInitializing"
                @mesh-initializing-error="onEndInitializing"
            />
        </div>
    </div>
</template>

<style lang="scss">
    @import '../../../../../style/bematrix';
</style>
