import self from '../..';
import config from 'defaultConfig';
import { Color3, Vector3, Quaternion, MeshBuilder, StandardMaterial, BoundingBox } from '@babylonjs/core';

const { collisionManager, gridManager, geometryUtility, highlightManager, meshManager } = self.app.modules;

const DEBUG_NORMALS = false;

const SelectionHelper = {
    /**
     * Create a copy of the mesh we wish to drag
     * Add this mesh to the higlight layer
     * Hide the original mesh
     * @param {*} entity
     */
    createSelectMesh(entity, checkRules = true) {
        const instance = entity.mesh;
        instance.checkCollisions = false;
        const selectMesh = instance.sourceMesh ? meshManager.meshUtility.CleanClone(instance.sourceMesh) : instance.clone();

        selectMesh.computeWorldMatrix(true);
        selectMesh.name = 'selectMesh';
        selectMesh.position = instance.position;
        selectMesh.rotationQuaternion = instance.rotationQuaternion;
        selectMesh.entity = entity;
        instance.selectMesh = selectMesh;
        instance.selectMesh.checkCollisions = true;
        // Parent the infills to selectMesh to ease the selection
        entity.optionsMeshes?.infills?.forEach((mesh) => {
            mesh.parent = selectMesh;
        });

        if (DEBUG_NORMALS) {
            this.createNormalDebugMeshes(selectMesh);
        }
        // Add subcolliders to the selectMesh
        if (entity.mesh.subCollidersBB) {
            selectMesh.subCollidersBB = entity.mesh.subCollidersBB;
        }
        this.checkRulesOfSelectMesh(selectMesh, checkRules);
        selectMesh.isVisible = true;
        selectMesh.setEnabled(true);
        geometryUtility.toggleMeshVisibility(instance, false, false);
        return selectMesh;
    },

    checkRulesOfSelectMesh(selectMesh, checkRules = true) {
        let highlightColor = Color3.Green();
        if (checkRules) {
            const isColliding = collisionManager.Controller.checkStaticCollisionsEntity(selectMesh.entity);
            const isOutsideGrid = !gridManager.isBBInsideGrid(selectMesh.getBoundingInfo().boundingBox);
            if (isColliding || isOutsideGrid) {
                highlightColor = Color3.Red();
            }
        }
        highlightManager.toggleHighlightMesh(selectMesh, true, highlightColor);
    },

    /**
     * This function is a special purpose function is was initialy created to
     * handle option selection
     *
     * The code may not fit other needs and must be modified carefully will testing options
     * selection in pdf mode
     *
     * @param {Mesh} mesh
     * @returns{Mesh} a new select mesh
     */
    createOptionSelectMesh(mesh) {
        const instance = mesh;
        instance.checkCollisions = false;

        const selectMesh = instance.sourceMesh ? meshManager.meshUtility.CleanClone(instance.sourceMesh) : instance.clone();
        const worldQuaternion = new Quaternion();
        const worldPosition = new Vector3();

        selectMesh.parent = null;

        instance.getWorldMatrix().decompose(null, worldQuaternion, worldPosition);

        selectMesh.name = 'selectMesh';

        selectMesh.setPivotPoint(Vector3.Zero());
        selectMesh.position = worldPosition;
        selectMesh.rotationQuaternion = worldQuaternion;

        selectMesh.originalMesh = instance;
        selectMesh.metadata = instance.metadata;

        instance.selectMesh = selectMesh;
        instance.selectMesh.checkCollisions = true;

        const isOutsideGrid = !gridManager.isBBInsideGrid(selectMesh.getBoundingInfo().boundingBox);
        let highlightColor = Color3.Green();
        if (isOutsideGrid) {
            highlightColor = Color3.Red();
        }

        highlightManager.toggleHighlightMesh(selectMesh, true, highlightColor);
        geometryUtility.toggleMeshVisibility(instance, false, true);

        return selectMesh;
    },

    /**
     * Remove this entity's mesh from the selectedMesh array and dispose the selectMesh
     * @param {*} entity
     */
    removeSelectMesh(entity) {
        // Reparent the infills from selectMesh to original mesh
        entity.optionsMeshes?.infills?.forEach((mesh) => {
            mesh.parent = entity.mesh;
        });
        const { selectMesh } = entity.mesh;
        if (!selectMesh) {
            return;
        }
        geometryUtility.toggleMeshVisibility(entity.mesh, true, false);
        geometryUtility.toggleMeshVisibility(selectMesh, false, false);
        selectMesh.checkCollisions = false;
        selectMesh.dispose();
        entity.mesh.selectMesh = null;
        entity.mesh.checkCollisions = true;
    },

    /**
     * Add the behavior that check the collisions and modifies the highlight layer associated
     * to the collision response.
     * @param {PointerDragBehavior} dragBehavior
     * @param {Mesh} mesh The mesh attached to the behavior
     */
    addHighlightBehavior(dragBehavior, mesh) {
        const entity = dragBehavior.attachedEntity;
        const highlightBehavior = highlightManager.createNewHighlightBehavior(mesh);

        const collisionBehavior = () => {
            const isColliding = collisionManager.Controller.checkStaticCollisionsEntity(entity);
            return !isColliding || isColliding === mesh.isColliding;
        };
        const gridBehavior = () => gridManager.isBBInsideGrid(mesh.getBoundingInfo().boundingBox);
        highlightBehavior.addHighlightBehaviorRules(collisionBehavior);
        highlightBehavior.addHighlightBehaviorRules(gridBehavior);

        dragBehavior.onDragStartObservable.add(() => {
            mesh.isColliding = false;
            mesh.collidedMesh = null;
            highlightBehavior.startHighlightBehavior();
        });
        dragBehavior.onDragObservable.add(() => {
            highlightBehavior.startHighlightBehavior();
        });
    },

    addMultiselectMeshHighlightBehavior(dragBehavior, meshes) {
        const highlightBehavior = highlightManager.createNewHighlightBehavior(meshes);
        SelectionHelper.generateNodeHighlightBehavior(highlightBehavior);
        dragBehavior.onDragObservable.add(() => {
            highlightBehavior.startHighlightBehavior();
        });
        highlightBehavior.setAttachedMeshesHighlightColor();
    },

    getHighlightBehavior(meshes) {
        if (meshes.length === 0) {
            return null;
        }
        const highlightBehavior = highlightManager.createNewHighlightBehavior(meshes);
        SelectionHelper.generateNodeHighlightBehavior(highlightBehavior);
        SelectionHelper.generateCollisionHighlightBehavior(highlightBehavior);
        highlightBehavior.setAttachedMeshesHighlightColor();
        return highlightBehavior;
    },

    generateNodeHighlightBehavior(highlightBehavior) {
        const gridBehavior = () => {
            const bb = meshManager.meshUtility.ComputeMeshListBB(highlightBehavior.meshes);
            const checkUnderGrid = highlightBehavior.meshes.some((mesh) =>
                collisionManager.CollisionHelper.isUnderGrid(mesh.entity, { isEntity: true }),
            );
            return gridManager.isBBInsideGrid(bb) && !checkUnderGrid;
        };
        highlightBehavior.addHighlightBehaviorRules(gridBehavior);
    },

    generateCollisionHighlightBehavior(highlightBehavior) {
        const collisionBehavior = () =>
            !highlightBehavior.meshes.some((mesh) => {
                const isColliding = collisionManager.Controller.checkStaticCollisionsEntity(mesh.entity);
                return isColliding && isColliding !== mesh.isColliding;
            });
        highlightBehavior.addHighlightBehaviorRules(collisionBehavior);
    },

    /**
     * Add the behavior that makes the drag moving the product on the virtual grid
     * @param {PointerDragBehavior} dragBehavior
     */
    addVirtualGridBehavior(dragBehavior) {
        dragBehavior.onDragObservable.add((event) => {
            SelectionHelper.translateByStep(dragBehavior.attachedNode, event.delta);
        });
    },

    /**
     * Function that compute the translation of a mesh by taking account
     * of the step given in the default-config
     * @param {Mesh} mesh the mesh we are translating
     * @param {Vector3} delta the value of the current translation
     */
    translateByStep(mesh, delta) {
        const toAdd = new Vector3();
        if (Math.abs(delta.x) > config.step) {
            const dragSteps = Math.floor(Math.abs(delta.x) / config.step);
            const xAxisDelta = Vector3.Right().scale(Math.sign(delta.x));
            xAxisDelta.normalizeToRef(toAdd);
            toAdd.scaleInPlace(config.step * dragSteps);
            mesh.position.addInPlace(toAdd);
        }
        if (Math.abs(delta.z) > config.step) {
            const dragSteps = Math.floor(Math.abs(delta.z) / config.step);
            const zAxisDelta = Vector3.Forward().scale(Math.sign(delta.z));
            zAxisDelta.normalizeToRef(toAdd);
            toAdd.scaleInPlace(config.step * dragSteps);
            mesh.position.addInPlace(toAdd);
        }
        mesh.computeWorldMatrix(true);
    },

    createNormalDebugMeshes(selectMesh) {
        const debugMaterialRed = new StandardMaterial('debug Mat red');
        debugMaterialRed.diffuseColor = Color3.Red();
        debugMaterialRed.emissiveColor = Color3.Red();
        const debugMaterialGreen = new StandardMaterial('debug Mat green');
        debugMaterialGreen.diffuseColor = Color3.Green();
        debugMaterialGreen.emissiveColor = Color3.Green();
        const debugMaterialBlue = new StandardMaterial('debug Mat blue');
        debugMaterialBlue.diffuseColor = Color3.Blue();
        debugMaterialBlue.emissiveColor = Color3.Blue();

        const normalMesh = MeshBuilder.CreateCylinder('normal', {
            height: 0.2,
            diameter: 0.005,
            tessellation: 3,
        });
        const parallelMesh = MeshBuilder.CreateCylinder('normal', {
            height: 0.2,
            diameter: 0.005,
            tessellation: 3,
        });
        const perpendicularMesh = MeshBuilder.CreateCylinder('normal', {
            height: 0.2,
            diameter: 0.005,
            tessellation: 3,
        });

        parallelMesh.parent = selectMesh;
        normalMesh.parent = selectMesh;
        perpendicularMesh.parent = selectMesh;

        //
        normalMesh.position.z += 0.1;
        parallelMesh.position.x += 0.1;
        perpendicularMesh.position.y += 0.1;

        normalMesh.rotate(Vector3.Left(), Math.PI / 2);
        parallelMesh.rotate(Vector3.Forward(), Math.PI / 2);
        perpendicularMesh.rotate(Vector3.Up(), Math.PI / 2);
        normalMesh.material = debugMaterialRed;
        parallelMesh.material = debugMaterialGreen;
        perpendicularMesh.material = debugMaterialBlue;
    },

    /**
     * Return the bounds of selected meshes
     * @param {Array<Mesh>} selectedMeshes the selected meshes
     * @returns the global bounding box
     */
    getBoundsOfSelection(selectedMeshes) {
        const bb = selectedMeshes[0].entity.getBoundingBox();
        let min = bb.minimumWorld;
        let max = bb.maximumWorld;
        for (let i = 1; i < selectedMeshes.length; i++) {
            const bb2 = selectedMeshes[i].entity.getBoundingBox();
            min = Vector3.Minimize(min, bb2.minimumWorld);
            max = Vector3.Maximize(max, bb2.maximumWorld);
        }
        return new BoundingBox(min, max);
    },
};

export default SelectionHelper;
