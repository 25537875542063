import CollisionHelper from '../helpers/collision-helper';

import self from '../../index';

/**
 * List of the entities that are able to collide with any mesh passed to the collision functions
 */
const CollidableEntities = ['frame', 'ledskin', 'backled-lightbox', 'sideled-lightbox', 'motionskin', 'corner', 'door', 'structural'];

/**
 * API for collisions detection and response
 */
export default class CollisionController {
    constructor() {
        this.dataStore = self.app.modules.dataStore;
        this.ground = null;
        this.entityList = [];

        self.app.events.on('@entity-manager.mesh-fetched', (entity) => {
            if (CollidableEntities.find((elem) => elem === entity.__name__)) {
                this.addEntity(entity);
            }
        });

        self.app.events.on('@entity-manager.mesh-disposed', (entity) => {
            this.removeEntity(entity);
        });

        self.app.events.on('@project-manager.project-loaded', () => {
            this.loadProjectEntityList();
        });
    }

    /**
     * Sets the ground that should be used for collisions
     * @param {Mesh} ground
     */
    setGround(ground) {
        this.ground = ground;
    }

    loadProjectEntityList() {
        this.entityList.length = 0;
        const entitiesList = this.dataStore.listEntities('/products/default');
        entitiesList.forEach((entity) => {
            if (CollidableEntities.find((elem) => elem === entity.__name__)) {
                this.entityList.push(entity);
            }
        });
    }

    /**
     * Adds an entity to the entities list to check collisions with
     * @param {*} entityRef
     * @returns {boolean}
     */
    addEntity(entityRef) {
        if (this.entityList.includes(entityRef)) {
            return false;
        }
        this.entityList.push(entityRef);
        return true;
    }

    /**
     * Remove this entity from the entities list
     * @param {*} entityRef
     * @returns {boolean}
     */
    removeEntity(entityRef) {
        const index = this.entityList.indexOf(entityRef);
        if (index !== -1) {
            this.entityList.splice(index, 1);
            return true;
        }
        return false;
    }

    /**
     * Returns true if the mesh collide with another mesh
     * @param {Mesh} movingMesh
     * @return {boolean}
     */
    checkStaticCollisionsMesh(meshToTest, meshRef) {
        return CollisionHelper.checkStaticCollisionsWithMesh(meshToTest, meshRef, this.entityList);
    }

    /**
     * Returns true if the entity collide with another entity
     * @param {*} entityToTest
     * @return {boolean}
     */
    checkStaticCollisionsEntity(entityToTest) {
        return CollisionHelper.checkStaticCollisionsWithEntity(entityToTest, this.entityList);
    }
}
